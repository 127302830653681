import React, { useState } from 'react';
import districts from './districts';

const FilterBox = ({ categories, onFilterChange }) => {
  const [price, setPrice] = useState(0); // Initialize price as a number
  const [location, setLocation] = useState('');
  const [category, setCategory] = useState('');
  const [dateFilter, setDateFilter] = useState('');

  const handlePriceChange = (e) => {
    const newPrice = parseInt(e.target.value, 10); // Parse value to integer
    setPrice(newPrice);
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleDateFilterChange = (e) => {
    setDateFilter(e.target.value);
  };

  const handleFilter = () => {
    const filters = {
      price,
      location,
      category,
      dateFilter
    };
    onFilterChange(filters);
  };

  return (
    <div className="filter-box">
      <div className='filter-item'>
      <select value={dateFilter} onChange={handleDateFilterChange}>
        <option value="">Select Date Filter</option>
        <option value="past_hour">Past Hour</option>
        <option value="past_day">Past Day</option>
        <option value="all_time">All Time</option>
      </select>
      </div>
     <div className='filter-item'>
     <select value={category} onChange={handleCategoryChange}>
        <option value="">Select Category</option>
        {categories.map((category, index) => (
          <option key={index} value={category}>{category}</option>
        ))}
      </select>
     </div>
     <div className='filter-item'>
     <select value={location} onChange={handleLocationChange}>
        <option value="">Select Location</option>
        {districts.map((district, index) => (
          <option key={index} value={district}>{district}</option>
        ))}
      </select>
</div>
<div className='filter-item'>
<input type="range"
        min="0"
        max="100000000" // Maximum price value in UGX
        step="100000" // Increment by 100,000 UGX
        value={price}
        onChange={handlePriceChange}
      />
      <p>Price: {price.toLocaleString()} UGX</p> {/* Format price with commas */}
</div>
   
      <button className='removebtn' onClick={handleFilter}>Filter</button>
    </div>
  );
};

export default FilterBox;
