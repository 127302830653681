import React, { useEffect, useState } from "react";
import './post.css'
import { Link } from 'react-router-dom';
import Compressor from 'compressorjs'; 
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; 
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db, storage } from "../../firebase/firebase";
import { useAuth } from "../../contexts/authContext";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Navbar from "../Nav/Navbar";
import Pay from "./Pay";


const Post = ({title }) => {
  const [data, setData] = useState({});
  const { currentUser } = useAuth();
  const useremail = currentUser.email;
  const useruid = currentUser.uid;
  const [images, setImages] = useState([]);
  const [isCompressing, setIsCompressing] = useState(false);
  const [uploadingImages, setUploadingImages] = useState([]);
  const [category, setCategory] = useState('');
  const [price, setPrice] = useState('');
  const [userData, setUserData] = useState(null);
  const [formData, setFormData] = useState({
    condition:'',
    bedrooms: '',
    bathrooms:'',
    toilets:'',
    furnishing:'',
    propertytype:'',
    propertysize:'',
    parkingspace:'',
    listingby:'',
    negotiation: '',
    description:'',
    servicecharge:'',
    paymentper:'',
    propertytitle:'',
    propertyuse:'',
  });
  

  const [facilities, setfacilities] = useState({
    freeWifi: false,
    parking: false,
    swimmingPool: false,
    gym: false,
    security: false,
    garden: false,
  });
  const handleFacilityChange = (facility) => {
    setfacilities({
      ...facilities,
      [facility]: !facilities[facility],
    });
  };
  const handlepricechange = (e) => {
    // Remove non-digit characters from input
    const inputValue = e.target.value.replace(/\D/g, '');
    
    // Format the input value with commas for thousands separators
    const formattedValue = Number(inputValue).toLocaleString('en-US');

    // Update the state with the formatted value
    setPrice(formattedValue);
  };
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleNegotiationChange = (e) => { // New handler for negotiation
    setFormData({
      ...formData,
      negotiation: e.target.value
    });
  };

  const renderInputsForCategory = () => {
    switch (category) {
      case 'Houses & Apartments for Sale':
        return (
          <>
            
            <input type="text" id="propertytitle" name="propertytitle" placeholder="Estate Name" value={formData.propertytitle} onChange={handleChange} />
            <br/>
          
            <select id="propertytype" name="propertytype" value={formData.propertytype} onChange={handleChange}>
              <option value="">Property Type</option>
              <option value="Apartment">Apartment</option>
              <option value="Block of flats">Block of flats</option>
              <option value="Bungalow">Bungalow</option>
              <option value="Chalet">Chalet</option>
              <option value="Condo">Condo</option>
              <option value="Duplex">Duplex</option>
              <option value="Farm House">Farm House</option>
              <option value="House">House</option>
            </select>
            <br/>
            <input type="condition" id="condition" name="condition" placeholder="Condition" value={formData.condition} onChange={handleChange} />
           
            <br/>
          
            <select id="bedrooms" name="bedrooms" value={formData.bedrooms} onChange={handleChange}>
              <option value="">Number of BedRooms</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5+">5+</option>
            </select>
            <br/>
            
            <select id="bathrooms" name="bathrooms" value={formData.bathrooms} onChange={handleChange}>
              <option value="">Number of BathRooms</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5+">5+</option>
            </select>
            <br/>
            
            <select id="toilets" name="toilets" value={formData.toilets} onChange={handleChange}>
              <option value="">Number of Toilets</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5+">5+</option>
            </select>
            <br/>
            
            <select id="furnishing" name="furnishing" value={formData.furnishing} onChange={handleChange}>
              <option value="">Furnshing</option>
              <option value="Furnished">Furnished</option>
              <option value="Semi-Furnished">Semi-Furnished</option>
              <option value="Unfurnished">Unfurnished</option>
            </select>
            <br/>
           
            <div>
              <h2>Facilities</h2>
      <div>
        <label>
          <input
            type="checkbox"
            checked={facilities.freeWifi}
            onChange={() => handleFacilityChange('freeWifi')}
          />
          Free WiFi
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={facilities.parking}
            onChange={() => handleFacilityChange('parking')}
          />
          Parking
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={facilities.swimmingPool}
            onChange={() => handleFacilityChange('swimmingPool')}
          />
          Swimming Pool
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={facilities.gym}
            onChange={() => handleFacilityChange('gym')}
          />
          Gym
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={facilities.security}
            onChange={() => handleFacilityChange('security')}
          />
          Security
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={facilities.garden}
            onChange={() => handleFacilityChange('garden')}
          />
          Garden
        </label>
      </div>
      <h3>Selected Facilities:</h3>
      <ul>
        {Object.entries(facilities).map(([facility, selected]) => (
          selected && <li key={facility}>{facility}</li>
        ))}
      </ul>
            </div>
            <br/>
            
            <select id="listingby" name="listingby" value={formData.listingby} onChange={handleChange}>
              <option value="">Listing by</option>
              <option value="Any">Any</option>
              <option value="Agent">Agent</option>
              <option value="Developer">Developer</option>
              <option value="Owner">Owner</option>
             
            </select>
            <br/>
            <input type="text" id="propertysize" name="propertysize" placeholder="Property Size" value={formData.propertysize} onChange={handleChange} />
             <br/>
             <input type="text" id="description" name="description" placeholder="Description" value={formData.description} onChange={handleChange} />
            
            <br/>
            
            <input
                type="text"
                id="price"
                name="price"
                value={price}
                onChange={handlepricechange}
                placeholder="Price in Ugandan Shillings"
                required
             />
            <br/>
            <div>
              <p>Negotiation:</p>
              <label>
                <input type="radio" name="negotiation" value="yes" checked={formData.negotiation === 'yes'} onChange={handleNegotiationChange} />
                Yes
              </label>
              <label>
                <input type="radio" name="negotiation" value="no" checked={formData.negotiation === 'no'} onChange={handleNegotiationChange} />
                No
              </label>
              <label>
                <input type="radio" name="negotiation" value="not sure" checked={formData.negotiation === 'not sure'} onChange={handleNegotiationChange} />
                Not Sure
              </label>
              
          
            </div>

          </>
        );
      case 'Houses & Apartments for Rent':
        return (
        
            <>
            
            <input type="text" id="propertytitle" name="propertytitle" placeholder="Estate Name" value={formData.propertytitle} onChange={handleChange} required />
            <br/>
            
            <select id="propertytype" name="propertytype" value={formData.propertytype} onChange={handleChange} required>
              <option value="">Property Type</option>
              <option value="Apartment">Apartment</option>
              <option value="Block of flats">Block of flats</option>
              <option value="Bungalow">Bungalow</option>
              <option value="Chalet">Chalet</option>
              <option value="Condo">Condo</option>
              <option value="Duplex">Duplex</option>
              <option value="Farm House">Farm House</option>
              <option value="House">House</option>
            </select>
            <br/>
            <input type="condition" id="condition" name="condition" placeholder="Condition" value={formData.condition} onChange={handleChange} required/>
           
            <br/>
            
            <select id="bedrooms" name="bedrooms" value={formData.bedrooms} onChange={handleChange} required>
              <option value="">Number of BedRooms</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5+">5+</option>
            </select>
            <br/>
            
            <select id="bathrooms" name="bathrooms" value={formData.bathrooms} onChange={handleChange} required>
              <option value="">Number of BathRooms</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5+">5+</option>
            </select>
            <br/>
            
            <select id="toilets" name="toilets" value={formData.toilets} onChange={handleChange} required>
              <option value="">Number of Toilets</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5+">5+</option>
            </select>
            <br/>
            
            <select id="furnishing" name="furnishing" value={formData.furnishing} onChange={handleChange} required>
              <option value="">Furnshing</option>
              <option value="Furnished">Furnished</option>
              <option value="Semi-Furnished">Semi-Furnished</option>
              <option value="Unfurnished">Unfurnished</option>
            </select>
            <br/>
            
            <div>
              <h2>Facilities</h2>
          <div>
            <label>
              <input
                type="checkbox"
                checked={facilities.freeWifi}
                onChange={() => handleFacilityChange('freeWifi')}
              />
              Free WiFi
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                checked={facilities.parking}
                onChange={() => handleFacilityChange('parking')}
              />
              Parking
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                checked={facilities.swimmingPool}
                onChange={() => handleFacilityChange('swimmingPool')}
              />
              Swimming Pool
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                checked={facilities.gym}
                onChange={() => handleFacilityChange('gym')}
              />
              Gym
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                checked={facilities.security}
                onChange={() => handleFacilityChange('security')}
              />
              Security
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                checked={facilities.garden}
                onChange={() => handleFacilityChange('garden')}
              />
              Garden
            </label>
          </div>
      <h3>Selected Facilities:</h3>
      <ul>
        {Object.entries(facilities).map(([facility, selected]) => (
          selected && <li key={facility}>{facility}</li>
        ))}
      </ul>
            </div>
            <br/>
            
            <select id="listingby" name="listingby" value={formData.listingby} onChange={handleChange} required>
              <option value="">Listing by</option>
              <option value="Any">Any</option>
              <option value="Agent">Agent</option>
              <option value="Developer">Developer</option>
              <option value="Owner">Owner</option>
             
            </select>
            <br/>
            <input type="text" id="propertysize" name="propertysize" placeholder="Property Size" value={formData.propertysize} onChange={handleChange} required/>
             <br/>
             <input type="text" id="description" name="description" placeholder="Description" value={formData.description} onChange={handleChange} required/>
            
            <br/>
            <input
                type="text"
                id="price"
                name="price"
                value={price}
                onChange={handlepricechange}
                placeholder="Price in Ugandan Shillings"
             />
            <br/>
            
            <select id="paymentper" name="paymentper" value={formData.paymentper} onChange={handleChange}>
            <option value=" ">Payment Per</option>
              <option value="Per Month">Per Month</option>
              <option value="Per quater">Per quater</option>
              <option value="Per half-year">Per half-year</option>
            
             
            </select>

            <div>
              <p>Negotiation:</p>
              <label>
                <input type="radio" name="negotiation" value="yes" checked={formData.negotiation === 'yes'} onChange={handleNegotiationChange} />
                Yes
              </label>
              <label>
                <input type="radio" name="negotiation" value="no" checked={formData.negotiation === 'no'} onChange={handleNegotiationChange} />
                No
              </label>
              <label>
                <input type="radio" name="negotiation" value="not sure" checked={formData.negotiation === 'not sure'} onChange={handleNegotiationChange} />
                Not Sure
              </label>
              
          
            </div>

          </>
         
        );
      case 'Land & Plots for Sale':
        return (
          <>
            
            
            <input type="text" id="propertytitle" name="propertytitle" placeholder="Title" value={formData.propertytitle} onChange={handleChange} required/>
            <br/>
           
            <select id="type" name="type" value={formData.type} onChange={handleChange} required>
              <option value="">property type</option>
              <option value="Commercial Land">Commercial Land</option>
              <option value="Farmland ">Farmland</option>
              <option value="Industrial Land">Industrial Land</option>
              <option value="Mixed-Use Land">Mixed-Use Land</option>
              <option value="Quarry">Quarry</option>
             <option value="Residential Land">Residential Land</option>
            </select>
             <br/>
            
             
            <select id="propertyuse" name="propertyuse" value={formData.propertyuse} onChange={handleChange} required>
            <option value="">Property Use</option>
              <option value="Any">Any</option>
              <option value="Commercial">Commercial</option>
              <option value="Mixed">Mixed</option>
              <option value="Residential">Residential</option>
          
            </select>
            <br/>
            <div>
              <h2>Facilities</h2>
      <div>
        <label>
          <input
            type="checkbox"
            checked={facilities.freeWifi}
            onChange={() => handleFacilityChange('freeWifi')}
          />
          Free WiFi
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={facilities.parking}
            onChange={() => handleFacilityChange('parking')}
          />
          Parking
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={facilities.swimmingPool}
            onChange={() => handleFacilityChange('swimmingPool')}
          />
          Swimming Pool
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={facilities.gym}
            onChange={() => handleFacilityChange('gym')}
          />
          Gym
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={facilities.security}
            onChange={() => handleFacilityChange('security')}
          />
          Security
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={facilities.garden}
            onChange={() => handleFacilityChange('garden')}
          />
          Garden
        </label>
      </div>
      <h3>Selected Facilities:</h3>
      <ul>
        {Object.entries(facilities).map(([facility, selected]) => (
          selected && <li key={facility}>{facility}</li>
        ))}
      </ul>
            </div>
           
            <br/>
           
            <select id="listingby" name="listingby" value={formData.listingby} onChange={handleChange} required>
              <option value="">Listing By</option>
              <option value="Any">Any</option>
              <option value="Agent">Agent</option>
              <option value="Developer">Developer</option>
              <option value="Owner">Owner</option>
             
            </select>
            <br/>
            <input type="text" id="propertysize" name="propertysize" placeholder="Property Size" value={formData.propertysize} onChange={handleChange} required/>
             <br/>
             <input type="text" id="description" name="description" placeholder="Description" value={formData.description} onChange={handleChange} required/>
            
            <br/>
            <input
                type="text"
                id="price"
                name="price"
                value={price}
                onChange={handlepricechange}
                placeholder="Price in Ugandan Shillings"
                required
             />
            <br/>
            

            <div>
              <p>Negotiation:</p>
              <label>
                <input type="radio" name="negotiation" value="yes" checked={formData.negotiation === 'yes'} onChange={handleNegotiationChange} />
                Yes
              </label>
              <label>
                <input type="radio" name="negotiation" value="no" checked={formData.negotiation === 'no'} onChange={handleNegotiationChange} />
                No
              </label>
              <label>
                <input type="radio" name="negotiation" value="not sure" checked={formData.negotiation === 'not sure'} onChange={handleNegotiationChange} />
                Not Sure
              </label>
              
          
            </div>

          </>
        );
      case 'Commercial Property for Sale':
        return (
            <>
            
            <input type="text" id="propertytitle" name="propertytitle" placeholder="Title" value={formData.propertytitle} onChange={handleChange} required/>
            <br/>
                    
            <input type="condition" id="condition" name="condition" placeholder="Condition" value={formData.condition} onChange={handleChange} required/>
           
            <br/>
           
             <br/>
             
            <select id="propertyuse" name="propertyuse" value={formData.propertyuse} onChange={handleChange} required>
              <option value="">Property use:</option>
              <option value="Commercial">Commercial</option>
              <option value="Mixed">Mixed</option>
              <option value="Residential">Residential</option>
          
            </select>
            <br/>
            <div>
             <h2>Facilities</h2>
      <div>
        <label>
          <input
            type="checkbox"
            checked={facilities.freeWifi}
            onChange={() => handleFacilityChange('freeWifi')}
          />
          Free WiFi
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={facilities.parking}
            onChange={() => handleFacilityChange('parking')}
          />
          Parking
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={facilities.swimmingPool}
            onChange={() => handleFacilityChange('swimmingPool')}
          />
          Swimming Pool
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={facilities.gym}
            onChange={() => handleFacilityChange('gym')}
          />
          Gym
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={facilities.security}
            onChange={() => handleFacilityChange('security')}
          />
          Security
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={facilities.garden}
            onChange={() => handleFacilityChange('garden')}
          />
          Garden
        </label>
      </div>
      <h3>Selected Facilities:</h3>
      <ul>
        {Object.entries(facilities).map(([facility, selected]) => (
          selected && <li key={facility}>{facility}</li>
        ))}
      </ul>
            </div>
           
            <br/>
            
            <select id="listingby" name="listingby" value={formData.listingby} onChange={handleChange} required>
              <option value="">Listing by:</option>
              <option value="Any">Any</option>
              <option value="Agent">Agent</option>
              <option value="Developer">Developer</option>
              <option value="Owner">Owner</option>
             
            </select>
            <br/>
            <input type="text" id="propertysize" name="propertysize" placeholder="Property Size" value={formData.propertysize} onChange={handleChange} required/>
             <br/>
             <input type="text" id="description" name="description" placeholder="Description" value={formData.description} onChange={handleChange} required/>
            
            <br/>
            <input
                type="text"
                id="price"
                name="price"
                value={price}
                onChange={handlepricechange}
                placeholder="Price in Ugandan Shillings"
                required
             />
            <br/>
            

            <div>
            <h1>Negotiation:</h1>
              <label>
                <input type="radio" name="negotiation" value="yes" checked={formData.negotiation === 'yes'} onChange={handleNegotiationChange} />
                Yes
              </label>
              <label>
                <input type="radio" name="negotiation" value="no" checked={formData.negotiation === 'no'} onChange={handleNegotiationChange} />
                No
              </label>
              <label>
                <input type="radio" name="negotiation" value="not sure" checked={formData.negotiation === 'not sure'} onChange={handleNegotiationChange} />
                Not Sure
              </label>
              
          
            </div>

          </>
        );
      case 'Commercial Property for Rent':
        return (
          <>
            
            <input type="text" id="propertytitle" name="propertytitle" placeholder="Title" value={formData.propertytitle} onChange={handleChange} required/>
            <br/>
                    
            <input type="condition" id="condition" name="condition" placeholder="Condition" value={formData.condition} onChange={handleChange} required/>
           
            <br/>
           
             <br/>
            
            <select id="propertyuse" name="propertyuse" value={formData.propertyuse} onChange={handleChange} required>
              <option value="Any">Property Use:</option>
              <option value="Any">Any</option>
              <option value="Commercial">Commercial</option>
              <option value="Mixed">Mixed</option>
              <option value="Residential">Residential</option>
          
            </select>
                     
            <br/>
            
            <select id="listingby" name="listingby" value={formData.listingby} onChange={handleChange} required>
              <option value="">Listing by:</option>
              <option value="Any">Any</option>
              <option value="Agent">Agent</option>
              <option value="Developer">Developer</option>
              <option value="Owner">Owner</option>
             
            </select>
            <br/>
            <input type="text" id="propertysize" name="propertysize" placeholder="Property Size" value={formData.propertysize} onChange={handleChange} required/>
             <br/>
             <input type="text" id="description" name="description" placeholder="Description" value={formData.description} onChange={handleChange} required/>
            
            <br/>
            <input
                type="text"
                id="price"
                name="price"
                value={price}
                onChange={handlepricechange}
                placeholder="Price in Ugandan Shillings"
                required
             />
            <br/>
            
            <select id="paymentper" name="paymentper" value={formData.paymentper} onChange={handleChange}>
           
            <option value=""> Payment per:</option>
              <option value="Per Month">Per Month</option>
              <option value="Per quater">Per quater</option>
              <option value="Per half-year">Per half-year</option>
              <option value="Per annum">Per annum</option>
            </select>

            <div>
              <p>Negotiation:</p>
              <label>
                <input type="radio" name="negotiation" value="yes" checked={formData.negotiation === 'yes'} onChange={handleNegotiationChange} />
                Yes
              </label>
              <label>
                <input type="radio" name="negotiation" value="no" checked={formData.negotiation === 'no'} onChange={handleNegotiationChange} />
                No
              </label>
              <label>
                <input type="radio" name="negotiation" value="not sure" checked={formData.negotiation === 'not sure'} onChange={handleNegotiationChange} />
                Not Sure
              </label>
              
          
            </div>

          </>
        );
      case 'Short Let Property':
        return (
          <>
           
          </>
        );
      case 'Land & Plots for Rent':
        return (
          <>
            
            <input type="text" id="propertytitle" name="propertytitle" placeholder="Title" value={formData.propertytitle} onChange={handleChange} required/>
            <br/>
           
          
            <select id="type" name="type" value={formData.type} onChange={handleChange} required>
              <option value="">Property Type:</option>
              <option value="Commercial Land">Commercial Land</option>
              <option value="Farmland ">Farmland</option>
              <option value="Industrial Land">Industrial Land</option>
              <option value="Mixed-Use Land">Mixed-Use Land</option>
              <option value="Quarry">Quarry</option>
             <option value="Residential Land">Residential Land</option>
            </select>
             <br/>
           
             <br/>
            
            <select id="propertyuse" name="propertyuse" value={formData.propertyuse} onChange={handleChange}>
            <option value="">property Use</option>
              <option value="Any">Any</option>
              <option value="Commercial">Commercial</option>
              <option value="Mixed">Mixed</option>
              <option value="Residential">Residential</option>
          
            </select>
                     
            <br/>
            
            <select id="listingby" name="listingby" value={formData.listingby} onChange={handleChange} required>
              <option value="">Listing By:</option>
              <option value="Any">Any</option>
              <option value="Agent">Agent</option>
              <option value="Developer">Developer</option>
              <option value="Owner">Owner</option>
             
            </select>
            <br/>
            <input type="text" id="propertysize" name="propertysize" placeholder="Property Size" value={formData.propertysize} onChange={handleChange} required/>
             <br/>
             <input type="text" id="description" name="description" placeholder="Description" value={formData.description} onChange={handleChange} required/>
            
            <br/>
            <input
                type="text"
                id="price"
                name="price"
                value={price}
                onChange={handlepricechange}
                placeholder="Price in Ugandan Shillings"
                required
             />
            <br/>
           
            <select id="paymentper" name="paymentper" value={formData.paymentper} onChange={handleChange} required>
            <option value="">Payment Per:</option>
              <option value="Per Month">Per Month</option>
              <option value="Per quater">Per quater</option>
              <option value="Per half-year">Per half-year</option>
              <option value="Per annum">Per annum</option>
            </select>

            <div>
              <h1>Negotiation:</h1>
              <label>
                <input type="radio" name="negotiation" value="yes" checked={formData.negotiation === 'yes'} onChange={handleNegotiationChange} />
                Yes
              </label>
              <label>
                <input type="radio" name="negotiation" value="no" checked={formData.negotiation === 'no'} onChange={handleNegotiationChange} />
                No
              </label>
              <label>
                <input type="radio" name="negotiation" value="not sure" checked={formData.negotiation === 'not sure'} onChange={handleNegotiationChange} />
                Not Sure
              </label>
              
          
            </div>

          </>
        );
      case 'New Builds':
        return (
          <>
           
          </>
        );
      case 'Event Centres, Venues & Workstations':
        return (
          <>
            
          
          </>
        );
      default:
        return null;
    }
  };
// Define an array of districts
const districts = ["Kampala", "Gulu", "Lira", "Jinja", "Mbarara", "Mbale", "Arua", "Kasese", "Masaka", "Entebbe","Wakiso"];
  
// Define villages data based on the villages array
const villages = [
  {"district": "Kampala", "villages_list": ["Ntinda", "Makindye", "Kawempe", "Nansana", "Kisaasi"]},
  {"district": "Gulu", "villages_list": ["Pece", "Bardege", "Layibi", "Lacor", "Bungatira"]},
  {"district": "Lira", "villages_list": ["Ojwina", "Adyel", "Omito", "Agweng", "Aromo"]},
  {"district": "Jinja", "villages_list": ["Mpumudde", "Bugembe", "Walukuba", "Mafubira", "Njeru"]},
  {"district": "Mbarara", "villages_list": ["Kakoba", "Nyamitanga", "Ruti", "Kakiika", "Kamukuzi"]},
  {"district": "Mbale", "villages_list": ["Namakwekwe", "Nkoma", "Bungokho", "Bukonde", "Bukasakya"]},
  {"district": "Arua", "villages_list": ["Oli Division", "Pece Division", "River Oli", "Ediofe", "Pajulu"]},
  {"district": "Kasese", "villages_list": ["Kilembe", "Nyakatonzi", "Kahendero", "Kyalhumba", "Katwe"]},
  {"district": "Masaka", "villages_list": ["Nyendo", "Kimaanya", "Katwe", "Bukakata", "Kitovu"]},
  {"district": "Entebbe", "villages_list": ["Katabi", "Kitubulu", "Nakawuka", "Kigungu", "Namulanda"]},
  {"district": "Fort Portal", "villages_list": ["South Division", "North Division", "Kabarole", "Kabarole", "Karambi"]},
  {"district": "Soroti", "villages_list": ["Western Division", "Eastern Division", "Arabic", "Amuria", "Soroti"]},
  {"district": "Hoima", "villages_list": ["Central Division", "Eastern Division", "Western Division", "Kyabigambire", "Buhimba"]},
  {"district": "Masindi", "villages_list": ["Kihande", "Mukono", "Kijura", "Kiryandongo", "Karujubu"]},
  {"district": "Tororo", "villages_list": ["Western Division", "Eastern Division", "Osukuru", "Kirewa", "Nagongera"]},
  {"district": "Mukono", "villages_list": ["Goma Division", "Central Division", "Nakisunga", "Ntenjeru", "Nabuti"]},
  {"district": "Mityana", "villages_list": ["Myanzi", "Busimbi", "Kiboga", "Kalangala", "Kassanda"]},
  {"district": "Bushenyi", "villages_list": ["Ishaka", "Kyabugimbi", "Bumbaire", "Bwambara", "Kizinda"]},
  {"district": "Masindi", "villages_list": ["Kihande", "Mukono", "Kijura", "Kiryandongo", "Karujubu"]},
  {"district": "Kiboga", "villages_list": ["Kiboga", "Lwamata", "Kapeka", "Butemba", "Dwaniro"]},
  {"district": "Wakiso", "villages_list": ["Kireka","Kira", "Namugongo", "Bweyogerere", "Matugga", "Kasangati"]}
  // Add more districts and villages here as needed
];

// State to track selected district and village
const [selectedDistrict, setSelectedDistrict] = useState('');
const [selectedVillage, setSelectedVillage] = useState('');

// Function to handle district selection change
const handleDistrictChange = (e) => {
  const district = e.target.value;
  setSelectedDistrict(district);
  setSelectedVillage(''); // Reset selected village when district changes
}

// Function to handle village selection change
const handleVillageChange = (e) => {
  setSelectedVillage(e.target.value);
}

// Filter villages based on selected district
const filteredVillages = villages.find(item => item.district === selectedDistrict)?.villages_list || [];



  useEffect(() => {
    const compressAndUpload = async () => {
      setIsCompressing(true);
      const urls = [];

      for (let i = 0; i < images.length; i++) {
        const compressedImage = await compressImage(images[i]);
        const imageRef = ref(storage, `/mulitpleFiles/${images[i].name}`);
        const uploadTask = uploadBytesResumable(imageRef, compressedImage);

        setUploadingImages(prevImages => [...prevImages, images[i].name]);

        try {
          const snapshot = await uploadTask;
          const downloadURL = await getDownloadURL(snapshot.ref);
          urls.push(downloadURL);
        } catch (error) {
          console.error('Error uploading image:', error);
        }
      }

      setData((prevData) => ({
        ...prevData,
        imgs: urls,
      }));

      setIsCompressing(false);
      setUploadingImages([]); // Clear uploading images array
    };

    if (images.length > 0) {
      compressAndUpload();
    }
  }, [images]);

  const compressImage = async (image) => {
    return new Promise((resolve, reject) => {
      new Compressor(image, {
        quality: 0.6,
        success(result) {
          resolve(result);
        },
        error(e) {
          console.error('Error compressing image:', e);
          reject(e);
        },
      });
    });
  };

  
  const makeid = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const ids = makeid(24);

  const getCurrentDateTimeAsString = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    let hours = currentDate.getHours();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');
    const shortDateFormatString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${amOrPm}`;
    return shortDateFormatString;
  }

  const dateposted = getCurrentDateTimeAsString();

  
useEffect(() => {
  const fetchUserData = async () => {
    try {
      const userRef = doc(db, "users", useremail); // Assuming "users" is your Firestore collection
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        setUserData(userSnap.data());
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };

  fetchUserData();

  // Clean-up function to unsubscribe from the Firestore listener
  return () => {
    // Any cleanup code
  };
}, [useremail]); // Re-run effect when userEmail changes

  const handleAdd = async (e) => {
    e.preventDefault();
    if (isCompressing) {
      alert("Please wait while images are being compressed...");
      return;
    }

    try {
      const userFullName = userData ? userData.name : '';
      const userphoneNumber = userData ? userData.phoneNumber : '';
      const productData = {
        ...formData,
        price,
        category,
        useremail,
        useruid,
        dateposted,
        imgs: [],
        selectedDistrict,
        selectedVillage,
        facilities,
        userFullName,
        userphoneNumber,
      };

      const productRef = doc(db, "products", ids);
      await setDoc(productRef, productData);

      const productDocSnapshot = await getDoc(productRef);
      const productDocData = productDocSnapshot.data();

      const updatedProductData = {
        ...productDocData,
        imgs: [...productDocData.imgs, ...data.imgs],
      };

      await setDoc(productRef, updatedProductData);

      alert("Your advert has been posted successfully");
    } catch (err) {
      console.error("Error posting advert:", err);
      alert("Error posting advert");
    }
  };

  return (
    <div className="post">
      <Navbar/>
      <div className="headercontainer">
      <h1 className="headertitle">Post New Ad</h1>
     
      </div>
    <div className="postcontainer">
      {/* Links and form */}
      <form onSubmit={handleAdd}>
{/* please select a category   */}

      
      <select id="category" name="category" value={category} onChange={handleCategoryChange} required>
        <option value="">Select Category</option>
        <option value="Houses & Apartments for Sale"><h1>Houses & Apartments for Sale</h1></option>
        <option value="Houses & Apartments for Rent">Houses & Apartments for Rent</option>
        <option value="Land & Plots for Sale">Land & Plots for Sale</option>
        <option value="Commercial Property for Sale">Commercial Property for Sale</option>
        <option value="Commercial Property for Rent">Commercial Property for Rent</option>
        <option value="Short Let Property">Short Let Property</option>
        <option value="Land & Plots for Rent">Land & Plots for Rent</option>
        <option value="New Builds">New Builds</option>
        <option value="Event Centres, Venues & Workstations">Event Centres, Venues & Workstations</option>

      </select>
        {/* Input for selecting images */}
        <div>
          <label htmlFor="file">
            <h1>Add photos</h1>
            <AddPhotoAlternateIcon style={{ fontSize: '90px', color: 'gray' }} ></AddPhotoAlternateIcon>
            </label>
          <input
            type="file"
            id="file"
            multiple
            onChange={(e) => setImages(e.target.files)}
            style={{ display: "none" }}
            required
          />
        </div>
        {/* Display uploading images */}
        <div className="uploadimg">
          {/* Render the images being uploaded */}
          {uploadingImages.map((imageName, index) => (
            <div className="uploadingImage" key={index}>
              <img src={URL.createObjectURL(images[index])} alt={imageName} />
              <span>- Uploading...</span>
            </div>
          ))}
        </div>
       {/* Render uploaded images */}
        {/* {data.imgs && data.imgs.map((imgUrl, index) => (
          <div  key={index}>
            <div className="afterUpload">
            <img className="imgsafterupload" src={imgUrl} alt={`Uploaded Image ${index}`} />
            </div>
          </div>
        ))} */}
        {/* Other input fields */}
     
      <div>
        
        <select id="district" name="district" onChange={handleDistrictChange} value={selectedDistrict} required>
          <option value="">Select a district</option>
          {districts.map((district, index) => (
            <option key={index} value={district}>{district}</option>
          ))}
        </select>
        
        <br />

        
        <select id="village" name="village" onChange={handleVillageChange} value={selectedVillage}>
          <option value="">Select a village</option>
          {filteredVillages.map((village, index) => (
            <option key={index} value={village}>{village}</option>
          ))}
        </select>
      </div>
        {renderInputsForCategory()}
        {/* Submit button */}
      <div className="btndiv">
        {/* <button className="maroon-button">
          Pay
        </button> */}
        <button className="maroon-button" type="submit">
          {isCompressing ? "Processing..." : "Post Ad"}
        </button>
        </div>      
      </form>
    </div>
  </div>
  );
};

export default Post;
