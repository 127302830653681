import React, { useState, useEffect } from 'react';
import './msgs.css';
import Navbar from '../Nav/Navbar';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { useAuth } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress'; // Import CircularProgress

function Messages() {
    const { currentUser } = useAuth();
    const currentusername = currentUser.displayName;
    const currentuseremail = currentUser.email;
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true); // State for loading indicator
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const q = query(
                    collection(db, 'messages'),
                    where('receiveremail', '==', currentuseremail),
                    orderBy('dateofmessage', 'desc')
                );

                const querySnapshot = await getDocs(q);
                const messageMap = new Map();

                querySnapshot.docs.forEach(doc => {
                    const message = { id: doc.id, ...doc.data() };
                    if (!messageMap.has(message.senderemail) || messageMap.get(message.senderemail).dateofmessage < message.dateofmessage) {
                        messageMap.set(message.senderemail, message);
                    }
                });

                const messageData = Array.from(messageMap.values());
                setMessages(messageData);
            } catch (error) {
                console.error('Error fetching messages: ', error);
            } finally {
                setLoading(false); // Set loading to false when messages are fetched or error occurs
            }
        };

        fetchMessages();

        // Clean up function to unsubscribe from Firestore listener (if any)
        return () => {
            // If you have a Firestore listener, you can unsubscribe here
        };
    }, [currentuseremail]); // Include currentuseremail in the dependency array to rerun effect when it changes

    return (
        <div className='msgcontainer'>
            <Navbar />
            <div className='msgdiv'>
                <div className='msgs'>
                 
                    {loading ? ( // Conditionally render CircularProgress while loading
                       <div className='loader'> 
                        <CircularProgress style={{ color: 'orange' }} />
                        </div>
                    ) : (
                        <>
                            {messages.length === 0 ? (
                                <p>Oops, sorry! Your inbox is empty.</p>
                            ) : (
                                <ul>
                                    {messages.map(message => (
                                        <li key={message.id} onClick={() => navigate("/chat", { state: message })}>
                                            <div>
                                                <img src={message.userpic} alt="Profile" className="profilepic" />
                                            </div>
                                            <div>
                                                
                                                <h3 className='sender'>{message.sendername}</h3>
                                                <p className='themsg'>{message.msgsent}</p>
                                                <h6>{message.dateofmessage}</h6>
                                                {/* Render other message properties as needed */}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Messages;
