import React from 'react';
import Navbar from '../Nav/Navbar';

function Notifications() {
  // Assume notifications are fetched from a state or context
  const notifications = []; // Placeholder for notifications
  
  return (
    <div>
      <Navbar style={{ backgroundColor: 'blue', color: 'white', padding: '10px' }} />
      <div style={{ textAlign: 'center', fontSize: '24px', marginTop: '20px' }}>
        Notifications
      </div>
      {notifications.length === 0 && (
        <div style={{ textAlign: 'center', marginTop: '10px', fontStyle: 'italic', color: 'gray' }}>
          Sorry, no notifications.
        </div>
      )}
    </div>
  );
}

export default Notifications;
