// SafetyTips.js

import React from 'react';
import Navbar from '../Nav/Navbar';

const SafetyTips = () => {
  return (
    <>
      <Navbar />
      <div style={styles.container}>
        <h2 style={styles.heading}>Safety Tips</h2>
        <p style={styles.paragraph}>
          At Chipper Lands, we prioritize the safety and security of our users. Here are some safety tips to keep in mind while using our platform:
        </p>
        <ol style={styles.list}>
          <li>Always verify the identity of the property owner or agent before making any payments or providing personal information.</li>
          <li>Use secure payment methods such as Mobile Money or Visa Cards for transactions.</li>
          <li>Be cautious of deals that seem too good to be true. Scammers may try to lure you with unrealistically low prices.</li>
          <li>Do not share sensitive information such as your bank account details or passwords with anyone.</li>
          <li>Meet potential landlords or agents in public places and inform someone you trust about your meeting.</li>
          <li>Research the property and its surroundings thoroughly before making any commitments.</li>
          <li>Trust your instincts. If something feels off or suspicious, it's better to walk away.</li>
        </ol>
        <p style={styles.paragraph}>
          By following these safety tips, you can ensure a secure and enjoyable experience on Chipper Lands. If you encounter any suspicious activity or have concerns about a listing, please report it to our support team immediately.
        </p>
      </div>
    </>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
  },
  heading: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: 'maroon',
    marginBottom: '20px',
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '1.6',
    marginBottom: '15px',
  },
  list: {
    paddingLeft: '20px',
  },
};

export default SafetyTips;
