// BillingPolicy.js

import React from 'react';
import Navbar from '../Nav/Navbar';

const BillingPolicy = () => {
  const style = {
    color: 'maroon',
    margin:'15px',
  };

  return (
    <>
    <Navbar/>
    
    <div style={style}>
      <h2>Billing Policy</h2>
      <p>
        At Chipper Lands, we offer convenient payment options to make your transaction process smooth and hassle-free. We accept the following payment methods:
      </p>
      <ul>
        <li>Mobile Money (MTN Mobile Money, Airtel Money)</li>
        <li>Visa Cards</li>
      </ul>
      <p>
        All prices on our platform are listed in Ugandan Shillings (UGX). For your convenience, we provide secure payment processing for transactions in UGX.
      </p>
      <p>
        If you have any questions or concerns regarding billing or payment methods, please feel free to contact our customer support team.
      </p>
    </div>
    
    </>
  );
};

export default BillingPolicy;
