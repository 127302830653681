

import { useState, useEffect } from "react";
import "./filteredproducts.css"
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import Navbar from "../Nav/Navbar";
import SearchBar from "../Search/Search";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CircularProgress from '@material-ui/core/CircularProgress';
import FilterBox from "../FilterBox/FilterBox";

function Products() {
    const location = useLocation();
    const selectedCategory = location.state; // Access the category prop
console.log(selectedCategory)
    const [pdts, setPdts] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const handleImageLoad = () => {
        setLoading(false);
    };

    const [filters, setFilters] = useState({
        price: '',
        location: '',
        category: ''
      });
      const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
        // Perform filtering based on new filters
        // For example, call an API with the new filters
      };
      const categories = [
        'Houses & Apartments for Sale',
        'Houses & Apartments for Rent',
        'Land & Plots for Sale',
        'Commercial Property for Sale',
        'Commercial Property for Rent',
        'Short Let Property',
        'Land & Plots for Rent',
        'New Builds',
        'Event Centres, Venues & Workstations'
      ];

    useEffect(() => {
        const q = query(collection(db, "products"), where("category", "==", selectedCategory)); // Use selectedCategory
        const FetchingProducts = onSnapshot(q, (snapshot) => {
            const productsData = [];
            snapshot.forEach((doc) => {
                const { useremail, dateposted, category,condition, price, description,propertytitle,selectedDistrict,selectedVillage, imgs, bathrooms,
                    bedrooms,
                    toilets,
                    negotiation,
                    listingby,
                    paymentper,
                    propertytype,
                    propertyuse,
                    propertysize,
                    furnishing,
                    facilities,
                    userFullName,
                    userphoneNumber,
                        } = doc.data();
                // Check if imgs is an array and contains at least one element
                const productImages = Array.isArray(imgs) && imgs.length > 0 ? imgs : [];
                productsData.push({
                    id: doc.id,
                    price,
                    description,
                    propertytitle,
                    category,
                    condition,
                    useremail,
                    dateposted,
                    selectedVillage,
                    selectedDistrict,
                    imgs: productImages,
                    bathrooms,
                    bedrooms,
                    toilets,
                    negotiation,
                    listingby,
                    paymentper,
                    propertytype,
                    propertyuse,
                    propertysize,
                    furnishing,
                    facilities,
                    userFullName,
                    userphoneNumber,
                });
            });
            setPdts(productsData);
        }, (error) => {
            console.log(error);
        });

        return () => {
            FetchingProducts();
        };
    }, [selectedCategory]); // Make sure to include selectedCategory in the dependency array

    return (
        <div className='products'>
            <Navbar/>
            <SearchBar/>
            <div className="outter">
                <div className="cramp">
                <Link to={'/home'} className="hover:underline font-bold">Home</Link>    
          /  <h1>{selectedCategory}</h1> 
            
            </div>
            <div className="maincontainer">
            <div className="filterbox">
            <h2>Filter Ads</h2>
      <FilterBox categories={categories} onFilterChange={handleFilterChange} />
      
                </div>    
            {/* <div className='productscontainer'>
            
            <div className="productcard-container">
                {pdts.map((item, index) => (
                    <div key={item.id} className="productcard"  onClick={() => navigate(`/product/${item.category}/${item.propertytitle}`, { state: item })}>
                        {item.imgs.length > 0 && <img className="product_image" src={item.imgs[0]} alt="Product" />}
                       <div className="product_details">
                       <h1 className="product_price">UGX {item.price}</h1>
                       <h1 className="product_title">{item.propertytitle}</h1>
                       <h1 className="property_location">
                        <LocationOnIcon style={{ fontSize: '15px', color: 'gray' }} ></LocationOnIcon>
                        {item.selectedDistrict},{item.selectedVillage}
                        </h1>
                        </div>
                    </div>
                ))}
            </div>
            </div> */}
             <div className='productscontainer'>
          
            
            <div className="productcard-container">
            {pdts.map((item, index) => (
                <div key={item.id} className="productcard" onClick={() => navigate(`/product/${item.category}/${item.propertytitle}`, { state: item })}>
                    {loading && 
                     <div className="loader-container">
                     <CircularProgress style={{ color: 'orange' }} />
                     </div>
                     }
                    <img
                        className="product_image"
                        src={item.imgs[0]}
                        alt="Product"
                        onLoad={handleImageLoad}
                        style={{ display: loading ? 'none' : 'block' }}
                    />
                    <div className="product_details">
                        <h1 className="product_price">UGX {item.price}</h1>
                        <h1 className="product_title">{item.propertytitle}</h1>
                        <h1 className="property_location">
                            <LocationOnIcon style={{ fontSize: '15px', color: 'gray' }} />
                            {item.selectedDistrict},{item.selectedVillage}
                        </h1>
                    </div>
                </div>
            ))}
        </div>
        </div>
        </div>
        </div>
       
        </div>
    );
}

export default Products;
