import "./index.css";
import React from 'react'
import { useAuth } from '../../contexts/authContext'
import { Link,useNavigate } from 'react-router-dom'
//import Products from '../products/Products'
import Navbar from "../Nav/Navbar";
import Footer from "../Footer/Footer";
import Allproducts from "../products/Allproducts";
import SearchBar from "../Search/Search";
import Menu from "../menu/Menu";
import BannerAd from "../barner/Ad";

const Home = () => {
  
        const { currentUser } = useAuth()

    return (
        <div className="home">
            <Navbar/>
        <div className="searchbg">
            <SearchBar/>
        </div>    
        <div className="container">
        <div className="left">
       <Menu/>
        
        </div>
        <div className="right">
         <div className="ad">
         <BannerAd/>  
         </div>    
       <div className="products">

        <Allproducts productsheader="Trending ads"/>
        </div>
        </div>
        </div>
        <Footer/>
        </div>
    )
}

export default Home

