import React from 'react';
import './PrivacyPolicy.css'; // Import CSS file for styling
import Navbar from "../Nav/Navbar";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar/>

     <div className="privacy-policy-container">
      <h1 className="title">Privacy Policy</h1>
      <p className="description">
        At Chipper Lands, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose information about you when you use our website and services.
      </p>
      <h2 className="section-title">Information We Collect</h2>
      <p>
        We collect information you provide directly to us, such as when you create an account, post a property, or contact us for support. This information may include your name, email address, phone number, and any other information you choose to provide.
      </p>
      <h2 className="section-title">How We Use Your Information</h2>
      <p>
        We may use the information we collect from you to:
      </p>
      <ul>
        <li>Provide, maintain, and improve our services</li>
        <li>Communicate with you about your account or our services</li>
        <li>Respond to your inquiries and provide support</li>
        <li>Personalize and tailor your experience on our platform</li>
        <li>Comply with legal obligations</li>
      </ul>
      <h2 className="section-title">Information Sharing</h2>
      <p>
        We may share your information with third-party service providers who help us operate our platform, such as hosting providers, analytics providers, and customer support providers. We may also share information with law enforcement agencies, courts, or other government bodies when required to comply with legal obligations or protect our rights.
      </p>
      <h2 className="section-title">Changes to This Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review this Privacy Policy periodically for any updates.
      </p>
      <p className="last-updated">Last Updated: April 26, 2024</p>
    </div>
    </>
   
  );
}

export default PrivacyPolicy;
