import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { Link, useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  searchBarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '30px',
  },
  searchBar: {
    borderRadius: 10,
    border: '1px solid #ccc',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    padding: '18px 22px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '400px', // Set maximum width as needed
    width: '100%', // Take up full width inside the container
    backgroundColor: 'white',
    position: 'relative', // Needed for placeholder animation
  },
  input: {
    border: 'none',
    outline: 'none',
    flex: 1,
    marginLeft: 5,
  },
  searchIcon: {
    cursor: 'pointer',
    color: '#888',
    marginLeft: 'auto', // Pushes the icon to the right
  },
  searchResultsContainer: {
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '0px',
    marginTop: '10px',
    borderRadius:'8px'
  },
  searchResult: {
    // Add styles for individual search result items if needed
    padding:'20px',
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    gap:'2rem'
  },
  propertyimg:{
    height:'60px'
  },
  title:{
    fontSize:'20px'
  },
  price:{
    fontSize:'20px'
  }
});

function SearchBar() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const placeholders = ['Looking for...', 'Cheap Houses for rent..', 'Plots of Land...','New buildings....'];
  
  useEffect(() => {
    const fetchData = async () => {
      if (searchTerm.trim() !== '') {
        const q = query(
          collection(db, "products"),
          orderBy("propertytitle"),
          where("propertytitle", ">=", searchTerm),
          where("propertytitle", "<=", searchTerm + '\uf8ff')
        );
        const querySnapshot = await getDocs(q);
        const results = [];
        querySnapshot.forEach((doc) => {
          // Assuming doc.data() contains all the fields you mentioned
          results.push(doc.data());
        });
        setSearchResults(results);
      } else {
        setSearchResults([]);
      }
    };

    fetchData();
  }, [searchTerm]);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
    }, 2000); // Change placeholder every 2 seconds

    return () => clearInterval(interval);
  }, []);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className={classes.searchBarContainer}>
      <div className={classes.searchBar}>
        <input
          type="text"
          placeholder={placeholders[placeholderIndex]}
          className={classes.input}
          value={searchTerm}
          onChange={handleInputChange}
          id='searchbar'
        />
        <SearchIcon className={classes.searchIcon} />
      </div>
      <div className={classes.searchResultsContainer}>
        {searchResults.map((product, index) => (
          <div className={classes.searchResult} key={index} onClick={() => navigate("/product", { state: product })}>
            {product.imgs.length > 0 && <img className={classes.propertyimg} src={product.imgs[0]} alt="Product" />}
           <div>
            <h2 className={classes.title}>{product.propertytitle}</h2>
            <h2 className={classes.price}> {product.price}</h2>
            {/* Add other fields as needed */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SearchBar;
