import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './footer.css';
import { Link } from 'react-router-dom';
import Navbar from '../Nav/Navbar';

function Help() {
  return (
    <>
    <Navbar/>
   
    <div className='mobile-help'>
      <ul>
          <h1 className='HelpHeader'>About Chipper Lands</h1>
          <li>
           <Link to="/about-us">
              About Us
            <ArrowForwardIosIcon style={{ fontSize: '16px' }} className="arrow-icon"></ArrowForwardIosIcon>
            </Link>
          </li>
          <li>
           <Link to="/faqs">
              Faq
            <ArrowForwardIosIcon style={{ fontSize: '16px' }} className="arrow-icon"></ArrowForwardIosIcon>
            </Link>
          </li>
          <li>
           <Link to="/privacy-policy">
             Privacy Policy
            <ArrowForwardIosIcon style={{ fontSize: '16px' }} className="arrow-icon"></ArrowForwardIosIcon>
            </Link>
          </li>
          <li>
           <Link to="/safety">
              Safety Tips
            <ArrowForwardIosIcon style={{ fontSize: '16px' }} className="arrow-icon"></ArrowForwardIosIcon>
            </Link>
          </li>
          <li>
           <Link to="/billing-policy">
             Billing Policy
            <ArrowForwardIosIcon style={{ fontSize: '16px' }} className="arrow-icon"></ArrowForwardIosIcon>
            </Link>
          </li>
          <h1 className='HelpHeader'>Settings</h1>
          <li>
           
             Push Notifications

            
          </li>
          <li>
           <Link to="/help/">
              Country
              <span className="text-end">Uganda</span>
            </Link>
          </li> 
          <li>
           <Link to="/help/">
              Language
                <span className="text-end">English</span>
            </Link>
          </li>
          <h1 className='HelpHeader'>App Info</h1>
          <li>
           <Link to="/help/">
              App Version 1.001 
              <span className="text-end">Up to date</span>
            </Link>
          </li>

          </ul>
      </div>
      </>
  )
}

export default Help