import React from 'react';
import './NotFoundPage.css'; // CSS file for styling
import Navbar from '../Nav/Navbar';
import Footer from '../Footer/Footer';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'; // Importing ErrorOutlineIcon

const NotFoundPage = () => {
  return (
    <>
      <Navbar/>
      <div className="not-found-container">
        <ErrorOutlineIcon style={{ fontSize: '160px' }} className="error-icon" /> {/* Using ErrorOutlineIcon */}
        <h1 className="error-heading">Sorry!</h1>
        <p className="error-message">Oops! The page you are looking for does not exist.</p>
      </div>
      <Footer/>
    </>
  );
}

export default NotFoundPage;
