import React, { useState, useEffect } from 'react';
import './account.css';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useAuth } from '../../contexts/authContext';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../Nav/Navbar';
import Logout from '../header';
import Myads from './Myads';
import SettingsIcon from '@mui/icons-material/Settings';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import FeedbackIcon from '@mui/icons-material/Feedback';
import QuizIcon from '@mui/icons-material/Quiz';
import CallIcon from '@mui/icons-material/Call';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import WalletIcon from '@mui/icons-material/Wallet';
import SellIcon from '@mui/icons-material/Sell';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const Account = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const useremail = currentUser.email;
  const username = currentUser.displayName;
  const [userData, setUserData] = useState(null);
  

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userRef = doc(db, "users", useremail); // Assuming "users" is your Firestore collection
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserData(userSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
      }
    };

    fetchUserData();

    // Clean-up function to unsubscribe from the Firestore listener
    return () => {
      // Any cleanup code
    };
  }, [useremail]); // Re-run effect when userEmail changes

  return (
    <div className='accountpage'>
      <Navbar />
      <div className='accountcontainer'>
       
        <div className="profile-container">
          
        <div className='first'>
            <h6>settings</h6>  <SettingsIcon style={{ fontSize: '30px' }} className="settings-icon" onClick={() => navigate(`/account/${username}/settings`)} />
              </div>
          <div className="profile-details">
            
            <img src={currentUser.photoURL} alt="Profile" className="profile-picture" />
            <div className="profile-info">
            
           <div className="second"></div>
          
              <div>
                {userData ? (
                  <div>
                    
                    <h2>{userData.name}</h2>
                    <h2>{userData.email}</h2>
                    <h2>{userData.phoneNumber}</h2>
                    {/* Render other user data fields as needed */}
                  </div>
                ) : (
                  <h2>Loading user data...</h2>
                )}
              </div>
            </div>
          </div>
          <div className='acclinks'>
          <h1 className='accheader'>My Account</h1>   
            <ul>
          
            <li id='myads'>
            <section >
              <Link to="/account/myads/">
              <AdsClickIcon className='fsticon'></AdsClickIcon>
              My ads
              <ArrowForwardIosIcon style={{ fontSize: '16px' }} className="arrow-icon"></ArrowForwardIosIcon>
              </Link>
              </section>
              </li>
              <li>
                <section>
                <Link to="/feedback">
                <FeedbackIcon className='fsticon'></FeedbackIcon>
                Feedback
                <ArrowForwardIosIcon style={{ fontSize: '16px' }} className="arrow-icon"></ArrowForwardIosIcon>
                </Link>
                </section>
                </li>
              <li>
                <section>
                <Link to="/performance">
                <EqualizerIcon className='fsticon'></EqualizerIcon>
                Performance
                <ArrowForwardIosIcon style={{ fontSize: '16px' }} className="arrow-icon"></ArrowForwardIosIcon>
                </Link>
                </section>
                </li>
              <li>
              <section>
                <Link to="/prosales">
                <SellIcon className='fsticon'></SellIcon>
                Pro Sales
                <ArrowForwardIosIcon style={{ fontSize: '16px' }} className="arrow-icon"></ArrowForwardIosIcon>
                </Link>
                </section>
                </li>
               
              <li>
              <section>
                <Link to="/mybalance">
                <WalletIcon className='fsticon'></WalletIcon>
                USh 0 MY BALANCE
                <ArrowForwardIosIcon style={{ fontSize: '16px' }} className="arrow-icon"></ArrowForwardIosIcon>
                </Link>
                </section>
                </li>
              <li>
              <section>
                <Link to="/managerscall">
                <CallIcon className='fsticon'></CallIcon>
                Manager's call
                <ArrowForwardIosIcon style={{ fontSize: '16px' }} className="arrow-icon"></ArrowForwardIosIcon>
                </Link>
                </section>
                </li>
              <li>
              <section>
                <Link to="/faqs">
                <QuizIcon className='fsticon'></QuizIcon>
                Frequently Asked Questions
                <ArrowForwardIosIcon style={{ fontSize: '16px' }} className="arrow-icon"></ArrowForwardIosIcon>
                </Link>
                </section>
                </li>
            </ul>
            <Logout />
          </div>
        </div>
     
        <div className='myadsacc'>
          <h2>My Adverts</h2>
          <Myads />
        </div>
      </div>
    </div>
  );
}

export default Account;
