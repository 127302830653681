import React from 'react';

const MainBanner = ({ imageUrl, price, address, description }) => {
  return (
    <div className="house-banner">
      <img src={imageUrl} alt="House" className="house-image" />
      <div className="house-details">
        <h2>${price}</h2>
        <p>{address}</p>
        <p>{description}</p>
        <button>Contact Agent</button>
      </div>
    </div>
  );
};

export default MainBanner;
