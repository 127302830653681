// const myemail = "jjt200220@gmail.com";
// const senderemail = "Lukwagojoel@gmail.com";

// function createOrderedChatroom(email1, email2) {
//   const chatroom = email1 + email2;
//   const orderedChatroom = chatroom.split('').sort().join('');
//   return orderedChatroom;
// }

// const orderedChatroom = createOrderedChatroom(myemail, senderemail);

// console.log(orderedChatroom); 
// Output: 02222@@JJaaaeeggiiijjklllmmmootuw


// chatroomHelper.js
export const createOrderedChatroom = (email1, email2) => {
    const chatroom = email1 + email2;
    const orderedChatroom = chatroom.split('').sort().join('');
    return orderedChatroom;
  };
  