import React from 'react'
import Navbar from '../Nav/Navbar'
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Myads from './Myads'


function Myadspage() {
  return (
    <div>
        <Navbar/>
        <div className='myadspage'>
        <Link to={'/account'} className='backtoaccount'>
            <ArrowBackIosIcon className='backicon' /> 
            Account
        </Link>
        <h1 className='myadsheader'>My Adverts</h1>
        <Myads/>
        </div>
    </div>
  )
}

export default Myadspage