import React, { useState } from 'react';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

import Post from './Post';

export default function Pay() {
  const [paymentCompleted, setPaymentCompleted] = useState(false);

  const config = {
    public_key: 'FLWPUBK_TEST-53a2db5ed495f29bf86a478f99932019-X',
    tx_ref: Date.now(),
    amount: 1000, // Amount in UGX
    currency: 'UGX', // Currency in Uganda Shillings
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: 'jjt200220@gmail.com',
      phone_number: '0706754002',
      name: 'lukwago joel',
    },
    customizations: {
      title: 'chipper lands',
      description: 'Payment for posting your ad',
      logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  const handlePayment = () => {
    handleFlutterPayment({
      callback: (response) => {
        console.log(response);
        if (response.status === 'successful') {
          setPaymentCompleted(true);
          closePaymentModal(); // Close the payment modal programmatically
        }
      },
      onClose: () => {},
    });
  };

  return (
    <div>
      {!paymentCompleted ? (
        <div>
          <h1>Hello Test user</h1>
          <button onClick={handlePayment}>Make Payment</button>
        </div>
      ) : (
        <Post/>
      )}
    </div>
  );
}
