// HiringPage.js

import React from 'react';
import Navbar from '../Nav/Navbar';

const Hiring = () => {
  return (
    <>
    <Navbar/>
    <div style={styles.container}>
      <h2 style={styles.heading}>We are Hiring</h2>
      <p style={styles.paragraph}>
        Thank you for your interest in joining Chipper Lands! We are always on the lookout for talented individuals to join our team and contribute to our mission of providing a seamless experience for property owners and users.
      </p>
      <p style={styles.paragraph}>
        We are currently in the process of expanding our team and will be posting job openings soon. Check back later for opportunities to join us!
      </p>
      <p style={styles.paragraph}>
        In the meantime, you can learn more about Chipper Lands and our mission by exploring the rest of our website. If you have any questions or would like to express your interest in future job opportunities, feel free to contact us.
      </p>
      <p style={styles.paragraph}>
        We appreciate your patience and look forward to potentially working with you in the future.
      </p>
      <p style={styles.signature}>
        Sincerely,<br />
        The Chipper Lands Team
      </p>
    </div>
    </>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
  },
  heading: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333', // Dark gray
    marginBottom: '20px',
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '1.6',
    marginBottom: '15px',
  },
  signature: {
    marginTop: '30px',
    fontStyle: 'italic',
    fontSize: '14px',
  },
};

export default Hiring;
