import React from 'react'
import SearchBartest from './Searchtest'
import Navbar from '../Nav/Navbar'

function Searchpage() {
  return (
    <>
    <Navbar/>
    <SearchBartest/>
    </>
  )
}

export default Searchpage