import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebase/firebase";

const DeleteProduct = async (productId,collections,productname) => {
  let item;
  if (collections==='saved'){
     item ="wishlist"
  }else{
     item ="Property List"
  }
  try {
    await deleteDoc(doc(db, collections, productId));
     alert(`${productname} has been removed from your ${item}`)
    
  } catch (error) {
    console.error("Error deleting product: ", error);
  }
};

export default DeleteProduct;
