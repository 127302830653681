import React from 'react';
import './TermsConditions.css'; // Import CSS file for styling
import Navbar from '../Nav/Navbar';

const TermsConditions = () => {
  return (
    <>
    <Navbar/>
    <div className="terms-conditions-container">
      <h1 className="title">Terms & Conditions</h1>
      <p className="last-updated">Last Updated: April 26, 2024</p>

      <h2 className="section-title">1. Acceptance of Terms</h2>
      <p>
        By accessing or using Chipper Lands website and services, you agree to be bound by these Terms & Conditions. If you do not agree with any part of these terms, you may not access or use our platform.
      </p>

      <h2 className="section-title">2. User Accounts</h2>
      <p>
        In order to access certain features of Chipper Lands, you may be required to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
      </p>

      <h2 className="section-title">3. Posting Content</h2>
      <p>
        By posting content on Chipper Lands, you represent and warrant that you have the necessary rights and permissions to do so. You agree not to post any content that is unlawful, offensive, or violates the rights of others.
      </p>

      <h2 className="section-title">4. Limitation of Liability</h2>
      <p>
        Chipper Lands and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the platform.
      </p>

      <h2 className="section-title">5. Governing Law</h2>
      <p>
        These Terms & Conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
      </p>

      <h2 className="section-title">6. Changes to This Agreement</h2>
      <p>
        Chipper Lands reserves the right to update or modify these Terms & Conditions at any time without prior notice. Your continued use of the platform after any such changes constitutes acceptance of the new Terms & Conditions.
      </p>
    </div>
    </>
    
  );
}

export default TermsConditions;
