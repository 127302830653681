import { useLocation, Link} from "react-router-dom";
import "./singleproduct.css";
import Navbar from "../Nav/Navbar";
//import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; 
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useAuth } from '../../contexts/authContext'
import { useState, useRef } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai"; // Import icons from react-icons
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SendIcon from '@mui/icons-material/Send';
import Allproducts from "../products/Allproducts";
import MainBanner from "../barner/Myadbanner";
import { createOrderedChatroom } from "../Sub_functions/chatroom";
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';


const Singleproduct = () => {
  const [msgsent, setmsgsent] = useState('');
 
  const location = useLocation();
  const data = location.state;
    ///current user email
    const { currentUser } = useAuth()
    const userpic =currentUser.photoURL;
    const senderemail = currentUser ? currentUser.email : 'default@example.com'; // Provide a default email
    const sendername = currentUser ? currentUser.displayName : 'Guest'; // Provide a default name

    const propertytitle= data.propertytitle;
    const description= data.description;
    const category= data.category;
    const price=data.price;
    const receiveremail =data.useremail;
    const dateposted =data.dateposted;
    const district =data.selectedDistrict;
    const village =data.selectedVillage;
    const bedrooms=data.bedrooms;
    const bathrooms=data.bathrooms;
    const toilets=data.toilets;
    const condition=data.condition;
    const propertysize=data.propertysize;
    const propertytype=data.propertytype;
    const propertyuse=data.propertyuse;
    const negotiation=data.negotiation;
    const listingby=data.listingby;
    const paymentper =data.paymentper;
    const furnishing=data.furnishing;
    const facilities=data.facilites;
    const img1 = data.imgs[1];
    const carouselRef = useRef(null);
    const userFullName=data.userFullName;
    const userphoneNumber=data.userphoneNumber;
    const [showContact, setShowContact] = useState(false);

  console.log(data)
 // const [scrollPosition, setScrollPosition] = useState(0);

  const scrollCarousel = (direction) => {
    const carousel = carouselRef.current;
    if (!carousel) return; // Ensure carousel reference is valid
  
    const scrollAmount = 200; // Adjust the scroll amount as needed
    const scrollWidth = carousel.scrollWidth;
    const clientWidth = carousel.clientWidth;
  
    let newPosition = carousel.scrollLeft;
  
    if (direction === "left") {
      newPosition -= clientWidth;
      if (newPosition < 0) {
        newPosition = 0;
      }
    } else if (direction === "right") {
      newPosition += clientWidth;
      if (newPosition + clientWidth > scrollWidth) {
        newPosition = scrollWidth - clientWidth;
      }
    }
  
    carousel.scrollTo({
      left: newPosition,
      behavior: "smooth",
    });
  };

///full screen corousel
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
const handleImageClick = (index) => {
  setCurrentImageIndex(index);
  setIsFullScreen(true);
};

const handleNextImage = () => {
  setCurrentImageIndex((prevIndex) =>
    prevIndex === data.imgs.length - 1 ? 0 : prevIndex + 1
  );
};

const handlePreviousImage = () => {
  setCurrentImageIndex((prevIndex) =>
    prevIndex === 0 ? data.imgs.length - 1 : prevIndex - 1
  );
};

const closeFullScreen = () => {
  setIsFullScreen(false);
};

  
  //the chat functionality
  const getCurrentDateTimeAsString = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    let hours = currentDate.getHours();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');
    const shortDateFormatString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${amOrPm}`;
    return shortDateFormatString;
  }


  const dateofmessage = getCurrentDateTimeAsString();

  const handleChange = (e) => {
    setmsgsent(e.target.value);
  };


  const makeid=(length)=> {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  
  const chatids = makeid(30);
  const chatroomID = createOrderedChatroom(receiveremail, senderemail);

  const sendmsg = async (e) => {
    e.preventDefault();
    try {
      
      await setDoc(doc(db, "messages", chatids), {
        chatids,
        receiveremail,
        senderemail,
        sendername,
        msgsent,
        dateofmessage,
        chatroomID,
        userpic
      });
      alert("message sent successfully")
      //navigate(-1)
    } catch (err) {
      console.log(err);
      alert(err)
    }
  };

    const saveproperty = async () => {
    const useremail=senderemail;
    const savedptdid= useremail+propertytitle;
    try {
      if (!currentUser) {
        throw new Error("User not authenticated.");
      }

      await setDoc(doc(db, "saved",savedptdid ),
       {
        propertytitle,
        description,
        category,
        price,
        dateposted,
        district,
        village,
        bedrooms,
        bathrooms,
        toilets,
        useremail,
        propertysize,
        propertytype,
        propertyuse,
        negotiation,
        listingby,
        furnishing,
        condition,
        facilities: data.facilities, 
        imgs:data.imgs,
        userphoneNumber,
        userFullName,
        selectedDistrict: district,
        selectedVillage: village,
        category,
        paymentper,
      });
      alert(`${propertytitle} has been added to your wishlist successfully` )
      //navigate(-1)
    }catch (error) {
      console.error("Error saving property: ", error);
    }
  };
  const renderDetailsForCategory = () => {
    switch (category) {
      case 'Houses & Apartments for Sale':
        return (
        <>
         <h2>bedrooms:{bedrooms}</h2>
            <h2>bathrooms:{bathrooms}</h2>
            <h2>toilets:{toilets}</h2>
            <h2>furnishing:{furnishing}</h2>
            <h2>propety type:{propertytype}</h2>
            <h2>propety size:{propertysize}</h2>
            <h2>propety use:{propertyuse}</h2>
            <h2>negotiation:{negotiation}</h2>
            <h2>listing by:{listingby}</h2>
            <h2 className="product_facilities">
                Facilities: {data.facilities && Object.keys(data.facilities).filter((key) => data.facilities[key]).join(", ")}
              </h2>
            <h2></h2>
        </>
        );
      case 'Houses & Apartments for Rent':
        return (
        
            <>
             <h2>bedrooms:{bedrooms}</h2>
            <h2>bathrooms:{bathrooms}</h2>
            <h2>toilets:{toilets}</h2>
            <h2>furnishing:{furnishing}</h2>
            <h2>propety type:{propertytype}</h2>
            <h2>propety size:{propertysize}</h2>
            <h2>propety use:{propertyuse}</h2>
            <h2>negotiation:{negotiation}</h2>
            <h2>listing by:{listingby}</h2>
            <h2 className="product_facilities">
                Facilities: {data.facilities && Object.keys(data.facilities).filter((key) => data.facilities[key]).join(", ")}
              </h2>
            <h2></h2>
            </>
         
        );
      case 'Land & Plots for Sale':
        return (
          <>
            <h2>propety type:{propertytype}</h2>
            <h2>propety size:{propertysize}</h2>
            <h2>propety use:{propertyuse}</h2>
            <h2>negotiation:{negotiation}</h2>
            <h2>listing by:{listingby}</h2>
            <h2 className="product_facilities">
                Facilities: {data.facilities && Object.keys(data.facilities).filter((key) => data.facilities[key]).join(", ")}
              </h2>
            <h2></h2> 
          </>
        );
      case 'Commercial Property for Sale':
        return (
          <>
           <h2>bedrooms:{bedrooms}</h2>
            <h2>bathrooms:{bathrooms}</h2>
            <h2>toilets:{toilets}</h2>
            <h2>furnishing:{furnishing}</h2>
            <h2>propety type:{propertytype}</h2>
            <h2>propety size:{propertysize}</h2>
            <h2>propety use:{propertyuse}</h2>
            <h2>negotiation:{negotiation}</h2>
            <h2>listing by:{listingby}</h2>
            <h2 className="product_facilities">
                Facilities: {data.facilities && Object.keys(data.facilities).filter((key) => data.facilities[key]).join(", ")}
              </h2>
            <h2></h2>
          </>
        );
      case 'Commercial Property for Rent':
        return (
        <>
         <h2>bedrooms:{bedrooms}</h2>
            <h2>bathrooms:{bathrooms}</h2>
            <h2>toilets:{toilets}</h2>
            <h2>furnishing:{furnishing}</h2>
            <h2>propety type:{propertytype}</h2>
            <h2>propety size:{propertysize}</h2>
            <h2>propety use:{propertyuse}</h2>
            <h2>negotiation:{negotiation}</h2>
            <h2>listing by:{listingby}</h2>
            <h2 className="product_facilities">
                Facilities: {data.facilities && Object.keys(data.facilities).filter((key) => data.facilities[key]).join(", ")}
              </h2>
            <h2></h2>
        </>
        );
      case 'Short Let Property':
        return (
          <>
           
          </>
        );
      case 'Land & Plots for Rent':
        return (
        <>
         <h2>propety type:{propertytype}</h2>
            <h2>propety size:{propertysize}</h2>
            <h2>propety use:{propertyuse}</h2>
            <h2>negotiation:{negotiation}</h2>
            <h2>listing by:{listingby}</h2>
            <h2 className="product_facilities">
                Facilities: {data.facilities && Object.keys(data.facilities).filter((key) => data.facilities[key]).join(", ")}
              </h2>
            <h2></h2> 
        </>
        );
      case 'New Builds':
        return (
          <>
           
          </>
        );
      case 'Event Centres, Venues & Workstations':
        return (
          <>
            
          
          </>
        );
      default:
        return null;
    }
  };
  return (
    <div className="productcontainer" >
     <Navbar/>
    
      <div className="product">
        <div className="imgleft">
        <div className="carousel-container">
        <button className="arrow arrow-left" onClick={() => scrollCarousel("left")}><AiOutlineLeft /></button>
        <div className="carousel" ref={carouselRef}>
          {data.imgs.map((imgUrl, index) => (
            
            <img key={index} src={imgUrl} alt={`Product ${index + 1}`} className="carousel-image" onClick={() => handleImageClick(index)} />
          
          ))}
          
        </div>
        <button className="arrow arrow-right" onClick={() => scrollCarousel("right")}><AiOutlineRight /></button>
     <BookmarkAddIcon onClick={saveproperty}className="wishlisticon" style={{ fontSize: '38px', color: 'white' }}/>

     {isFullScreen && (
        <div className="full-screen-overlay" onClick={closeFullScreen}>
          <button className="arrow arrow-left" onClick={(e) => { e.stopPropagation(); handlePreviousImage(); }}>
            <AiOutlineLeft />
          </button>
          <img
            src={data.imgs[currentImageIndex]}
            alt={`Product ${currentImageIndex + 1}`}
            className="full-screen-image"
          />
          <button className="arrow arrow-right" onClick={(e) => { e.stopPropagation(); handleNextImage(); }}>
            <AiOutlineRight />
          </button>
        </div>
      )}
      </div>
        </div>
        <div className="detailsright">
          <div className="pricediv">
          <h1 className="propertyprice">UGX {price}</h1> <h2 className='paymentper'>{` ${paymentper}`}</h2>     
          </div>
         <div className="chat">
         <h1 className="postedby">Posted by : {userFullName}</h1> 
         <div>
      {/* <button className="showphonenumber" onClick={() => setShowContact(!showContact)}>Show Contact</button>
      {showContact && (
        <div>
          <a href="tel:{userphoneNumber}" className="phoneNumber">{userphoneNumber}</a>
        </div>
      )} */}<div>
          <a href="tel:{userphoneNumber}" className="phoneNumber">{userphoneNumber}</a>
        </div>
    </div>
            <form onSubmit={sendmsg}>
                    <input
                    type="text"
                    value={msgsent}
                    onChange={handleChange}
                  /> 
                    <button className="sendbtn" type="submit">
                      Start chat
                      <SendIcon style={{ fontSize: '18px', color: 'white', marginLeft:'6px' }} ></SendIcon>
                    </button>
            </form>
          
         </div>
         
        </div>

      </div>
      <div className="detailscontainer">
          <h1 className="propertytitle">{propertytitle}</h1>
          <h1 className="category">{category}</h1>
          <h1 className="location"> <LocationOnIcon style={{ fontSize: '25px', color: 'gray' }} ></LocationOnIcon> {district},{village}</h1>
          <h1 className="dateposted">Posted : {dateposted}</h1> 
          <h1 className="dateposted">condition : {condition}</h1> 
         <p className="description">description: {description}</p>
          <div className="otherdetails">
            
           {renderDetailsForCategory()}
          </div>
      </div>
      <div className="chatmobile">
         <h1 className="postedby">Posted by : {userFullName}</h1> 
         <div>
      {/* <button className="showphonenumber" onClick={() => setShowContact(!showContact)}>Show Contact</button>
      {showContact && (
        <div>
          <a href="tel:{userphoneNumber}" className="phoneNumber">{userphoneNumber}</a>
        </div>
      )} */}<div>
          <a href="tel:{userphoneNumber}" className="phoneNumber">{userphoneNumber}</a>
        </div>
    </div>
            <form onSubmit={sendmsg}>
                    <input
                    type="text"
                    value={msgsent}
                    onChange={handleChange}
                  /> 
                    <button className="sendbtn" type="submit">
                      Start chat
                      <SendIcon style={{ fontSize: '18px', color: 'white', marginLeft:'6px' }} ></SendIcon>
                    </button>
                    <BookmarkAddIcon onClick={saveproperty}className="wishlisticonmobile" style={{ fontSize: '40px', color: 'white' }}/>
            </form>
           
      </div>

      <div className="similarproducts">
      
       <Allproducts productsheader="Similar Ads"/>
      </div>
    </div>
  );
};

export default Singleproduct;
