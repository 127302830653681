import React from 'react';
import './AboutUs.css'; // Import CSS file for styling
import Navbar from '../Nav/Navbar';

const AboutUs = () => {
  return (
    <>
     <Navbar></Navbar> 
     <div className="about-us-container">
     
      <h1 className="title">About Chipper Lands</h1>
      <p className="description">
        Chipper Lands is your go-to platform for posting real estate properties for sale and rent. We provide a seamless experience for both property owners and potential buyers or renters. Our mission is to connect people with their ideal properties quickly and efficiently.
      </p>
      <h2 className="subtitle">Our Features</h2>
      <ul className="feature-list">
        <li>Easy posting of properties for sale or rent</li>
        <li>Search functionality to find properties based on specific criteria</li>
        <li>Interactive maps to visualize property locations</li>
        <li>User-friendly interface for a smooth browsing experience</li>
        <li>Secure messaging system for communication between users</li>
      </ul>
      <h2 className="subtitle">Our Team</h2>
      <div className="team-members">
        <div className="team-member">
          <img src="team-member1.jpg" alt="Team Member 1" className="team-member-img" />
          <h3 className="member-name">Mwechumi Derrick</h3>
          <p className="member-role">CEO & Founder</p>
        </div>
        <div className="team-member">
          <img src="team-member2.jpg" alt="Team Member 2" className="team-member-img" />
          <h3 className="member-name">Lukwago Joel</h3>
          <p className="member-role">Lead Developer</p>
        </div>
        <div className="team-member">
          <img src="team-member3.jpg" alt="Team Member 3" className="team-member-img" />
          <h3 className="member-name"></h3>
          <p className="member-role">Marketing Manager</p>
        </div>
      </div>
    </div> 
    </>
    
  );
}

export default AboutUs;
