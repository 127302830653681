import React from 'react';
import './BannerAd.css'; // Import your CSS file for styling

function BannerAd() {
  return (
    <div className="banner">
      <div className="content">
        <h2>Sell Your Land here Cheaply!</h2>
        <p>Find your perfect buyers & sellers of land at unbeatable prices.</p>
       
      </div>
    </div>
  );
}

export default BannerAd;
