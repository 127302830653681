import React, { useState } from 'react';
import './settings.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useAuth } from '../../contexts/authContext';
import { Link } from 'react-router-dom';
import Navbar from '../Nav/Navbar';
import Logout from '../header';

const Settings = () => {
    const { currentUser } = useAuth();
    const useremail = currentUser.email;
    const username = currentUser.displayName;
    const [phoneNumber, setPhoneNumber] = useState('');
    const [gender, setGender] = useState('');
    const [location, setLocation] = useState('');
    const [businessDetails, setBusinessDetails] = useState('');
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
          const docRef = doc(db, "users", useremail);
          const docSnap = await getDoc(docRef);
          
          if (docSnap.exists()) {
            const existingData = docSnap.data();
      
            // Update only the fields that have been changed
            const newData = {
              phoneNumber: phoneNumber || existingData.phoneNumber,
              email: useremail,
              name: username,
              location: location || existingData.location,
              gender: gender || existingData.gender,
              businessdetails: businessDetails || existingData.businessdetails,
            };
      
            // Update the document
            await setDoc(docRef, newData, { merge: true });
            console.log("User data updated successfully!");
            alert("User data updated successfully!");
          } else {
            // Create the document
            await setDoc(docRef, {
              phoneNumber: phoneNumber,
              email: useremail,
              name: username,
              location: location,
              gender: gender,
              businessdetails: businessDetails,
            });
            console.log("User data created successfully!");
            alert("User data created successfully!");
          }
        } catch (error) {
          console.error("Error updating/creating user data: ", error);
          alert("Error updating/creating user data: ", error);
          // Handle error, maybe display an error message to the user
        }
    };

    return (
        <div className='settingspage'>
            <Navbar/>
            
            <Link to={`/account/${username}`} className='backlink'>
    <ArrowBackIosIcon className='backtoaccounticon' style={{fontSize:'16px'}} />
    
</Link>
            <div className="userprofile-container">
                <div className="userprofile-details">
                    <img src={currentUser.photoURL} alt="Profile" className="userprofile-picture" />
                    <div className="userprofile-info">
                        <h2>{currentUser.displayName}</h2>
                        <h2 className='email'>{currentUser.email}</h2>
                       </div>
                </div>
                <h3 className='heading3'>Edit your account details</h3>
                <form onSubmit={handleSubmit}>
                  <h3 className='phoneh3'>Enter phonenumber without the zero</h3>
                <input 
                    type="text" 
                    placeholder="+256XXXXXXXXX" 
                    value={phoneNumber} 
                    onChange={(e) => setPhoneNumber(e.target.value)} 
                    maxLength="10"
                    minLength="10"
                />
                    <select 
                        value={gender} 
                        onChange={(e) => setGender(e.target.value)}
                    >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                    </select>
                    <input 
                        type="text" 
                        placeholder="Enter Location (e.g., Kampala, Uganda)" 
                        value={location} 
                        onChange={(e) => setLocation(e.target.value)} 
                    />
                    <textarea 
                    id="message" name="message" rows="5"
                    placeholder="Enter Details" 
                    value={businessDetails} 
                    onChange={(e) => setBusinessDetails(e.target.value)} ></textarea>
                    
                    <button className='update' type="submit">Update</button>
                </form>
            </div>
            <Logout/>
        </div>
    );
};

export default Settings;
