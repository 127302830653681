import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';


const Footer = () => {
  const getCurrentYear = () => {
    const currentDate = new Date();
    return currentDate.getFullYear();
  };
  
  // Usage example:
  const currentYear = getCurrentYear();
  console.log(currentYear); // Output: Current year (e.g., 2024)
  
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-column">
          <h3>About Chipper Lands</h3>
          <ul>
            <li>
            <Link to="/about-us">About Us</Link>
            </li>
            <li>
            <Link to="/we-are-hiring!!">We are Hiring!</Link>
              </li>
            <li>
            <Link to="/Terms&conditions"> Terms & Conditions</Link> 
            </li>

            <li>
            <Link to="/privacy-policy">Privacy Policy</Link> 
             
            </li>

            <li>
            <Link to="/billing-policy">Billing Policy</Link> 
            </li>

            <li>
            <Link to="/copyright">Copyright Infringement Policy</Link> 
            </li>
            
          </ul>
        </div>
        <div className="footer-column">
          <h3>Support</h3>
          <ul>
            <li><a href="mailto:support@chipperlands.com">support@chipperlands.com</a></li>
            <li><Link to="/safety">Safety Tips</Link>
            </li>
            <li>
            <Link to="/contact-us">Contact Us</Link>  
                      
             </li>
            <li><Link to="/faqs">FAQ</Link></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Connect</h3>
          <ul>
            <li><a href="/face">Facebook</a></li>
            <li><a href="/ins">Instagram</a></li>
            <li><a href="/utu">YouTube</a></li>
            <li><a href="/whatsapp">WhatsApp</a></li>
          </ul>
        </div>
      </div>
      
      <p id='last'>{currentYear}@chipperlands </p>
    </footer>
  );
};

export default Footer;
