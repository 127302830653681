import Login from "./components/auth/login";
import Register from "./components/auth/register";
import Home from "./components/home";
import Post from "./components/post/Post";
import Products from "./components/products/Products";
import { AuthProvider } from "./contexts/authContext";
import { useRoutes, Navigate } from "react-router-dom";
import Singleproduct from "./components/singleproduct/Singleproduct";
import Allproducts from "./components/products/Allproducts";
import Account from "./components/Account/Account";
import Searchpage from "./components/Search/Searchpage";
import Messages from "./components/chat/Messages";
import Chat from "./components/chat/Chat";
import Saved from "./components/saved/Saved";
import NotFoundPage from "./components/Not-found/NotFoundPage";
import Pay from "./components/post/Pay"
import Settings from "./components/Account/Settings";
import Myadspage from "./components/Account/Myadspage";
import AboutUs from "./components/other-pages/About";
import Contact from "./components/other-pages/Contact";
import PrivacyPolicy from "./components/other-pages/Privacy-policy";
import FAQs from "./components/other-pages/Faqs";
import TermsConditions from "./components/other-pages/TCs";
import ScrollToTop from "./components/Sub_functions/ScrollToTop";
import Notifications from "./components/Notifications/Notifications";
import Help from "./components/Footer/Help";
import BillingPolicy from "./components/other-pages/BillingPolicy";
import CopyrightInfringementPolicy from "./components/other-pages/CopyrightInfringementPolicy ";
import SafetyTips from "./components/other-pages/SafetyTips";
import Hiring from "./components/other-pages/Hiring";

function App() {
  
  const routesArray = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/post/:username",
      element: <Post title="Post New Ad" />,
    },
    {
      path: "/products/:category",
      element: <Products />,
    },
    {
      path: "/all products",
      element: <Allproducts  />,
    },
    {
      path: "/product/:category/:producttitle",
      element: <Singleproduct/>,
    },
    {
        path: "/account/:username", // Adjust the path to include the username parameter
        element: <Account />,
      },
    {
      path: "/account/:username/settings/",
      element: <Settings/>,
    },
    {
      path: "/account/myads/",
      element: <Myadspage/>,
    },
    {
      path: "/search/",
      element: <Searchpage/>,
    },
    {
      path: "/messages/:username",
      element: <Messages/>,
    },
    {
      path: "/notifications/:username",
      element: <Notifications/>,
    },
    {
      path: "/chat/",
      element: <Chat/>,
    },
    {
      path: "/saved/:username",
      element: <Saved/>,
    },
    {
      path: "/pay/",
      element: <Pay/>,
    },
    {
      path: "/help/",
      element: <Help/>,
    },
    {
      path: "/about-us/",
      element: <AboutUs/>,
    },
    {
      path: "/contact-us/",
      element: <Contact/>,
    },
    {
      path: "/privacy-policy/",
      element: <PrivacyPolicy/>,
    },
    {
      path: "/faqs/",
      element: <FAQs/>,
    },
    {
      path:"/Terms&conditions",
      element:<TermsConditions/>
    },
    {
      path:"/billing-policy",
      element:<BillingPolicy/>
    },
    {
      path:"/copyright",
      element:<CopyrightInfringementPolicy/>
    },
    {
      path:"/safety",
      element:<SafetyTips/>
    },
    {
      path:"/we-are-hiring!!",
      element:<Hiring/>
    },
    // Add other routes as needed
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ];

  let routesElement = useRoutes(routesArray);

  return (
    <AuthProvider>
      <ScrollToTop />
      <div className="w-full h-screen flex flex-col">{routesElement}</div>
    </AuthProvider>
  );
}

export default App;
