import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/authContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import DeleteProduct from '../Sub_functions/DeleteDoc'
import Navbar from '../Nav/Navbar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress
import "./saved.css" 

function Saved() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading indicator
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    const fetchProducts = async () => {
      if (!currentUser) return; // Ensure currentUser is available

      const q = query(
        collection(db, "saved"),
        where("useremail", "==", currentUser.email)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedProducts = [];
        snapshot.forEach((doc) => {
          fetchedProducts.push({ id: doc.id, ...doc.data() });
        });
        setProducts(fetchedProducts);
        setLoading(false); // Set loading to false when products are fetched
      });

      return () => unsubscribe();
    };

    fetchProducts();
  }, [currentUser]); // Ensure useEffect runs when currentUser changes

  return (
    <div className="wishlistpage">
     <Navbar/>   
    <div className="wishlist-container">
      <h1 className="savedheader">Wishlist</h1>
    
      {loading ? ( // Conditionally render CircularProgress while loading
        <div className="loader">
          <CircularProgress style={{ color: 'orange' }} />
        </div>
      ) : (
        <>
          {products.length === 0 ? (
            <div className="empty-wishlist">
              <p>Sorry, you haven't saved any Property yet.</p>
            </div>
          ) : (
            <div className="savedproductcard-container">
              {products.map((product, index) => (
                <div key={product.id} className="savedproductcard" >
                < div className="savedimg"> {product.imgs.length > 0 && <img className="saved_image" src={product.imgs[0]} alt="Product"  onClick={() => navigate(`/product/${product.category}/${product.propertytitle}`, { state: product })}/>}</div>
                  <div className="saved_details">
                    <h1 className="product_price">UGX {product.price}</h1>
                    <h1 className="saved_title">{product.propertytitle}</h1>
                   
                    <h1 className="property_location">
                                <LocationOnIcon style={{ fontSize: '15px', color: 'gray' }} />
                                {product.selectedDistrict},{product.selectedVillage}
                     </h1>
                     <button className="removebtn" onClick={() => DeleteProduct(product.id,'saved',product.propertytitle)}>Remove
                    <DeleteForeverIcon className="savedDeleteicon" ></DeleteForeverIcon>
                    </button>

                  </div>
                 </div>
              ))}
            </div>
          )}
        </>
      )}
    
    </div>
    
    </div>
  );
}

export default Saved;
