// CopyrightInfringementPolicy.js

import React from 'react';
import Navbar from '../Nav/Navbar';

const CopyrightInfringementPolicy = () => {
  return (
    <>
      <Navbar />
      <div style={styles.container}>
        <h2 style={styles.heading}>Copyright Infringement Policy</h2>
        <p style={styles.paragraph}>
          Chipper Lands respects the intellectual property rights of others and expects its users to do the same. We have implemented a Copyright Infringement Policy to address instances of copyright violation on our platform.
        </p>
        <p style={styles.paragraph}>
          If you believe that your copyright-protected work has been used on Chipper Lands in a way that constitutes copyright infringement, please provide us with the following information:
        </p>
        <ol>
          <li style={styles.listItem}>A description of the copyrighted work you believe has been infringed.</li>
          <li style={styles.listItem}>The URL(s) or other identifying information of the allegedly infringing material(s) on Chipper Lands.</li>
          <li style={styles.listItem}>Your contact information, including your name, address, telephone number, and email address.</li>
          <li style={styles.listItem}>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.</li>
          <li style={styles.listItem}>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</li>
          <li style={styles.listItem}>Your electronic or physical signature.</li>
        </ol>
        <p style={styles.paragraph}>
          Please send this information to our designated Copyright Agent at [insert contact email or address]. Upon receipt of a valid notice, we will promptly investigate the matter and take appropriate action in accordance with applicable laws.
        </p>
        <p style={styles.paragraph}>
          It is our policy to terminate the accounts of users who are repeat infringers of others' copyrights.
        </p>
        <p style={styles.paragraph}>
          By submitting a notice of copyright infringement, you acknowledge and agree that we may forward your notice and any related communications to the user who posted the allegedly infringing material.
        </p>
        <p style={styles.paragraph}>
          If you have any questions or concerns about our Copyright Infringement Policy, please contact our support team.
        </p>
      </div>
    </>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
  },
  heading: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: 'maroon',
    marginBottom: '20px',
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '1.6',
    marginBottom: '15px',
  },
  listItem: {
    marginBottom: '10px',
  },
};

export default CopyrightInfringementPolicy;
