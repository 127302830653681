import React,{useState} from 'react';
import { useAuth } from '../../contexts/authContext'
import { useNavigate ,Link} from 'react-router-dom';
import './Nav.css';

import Login from '../auth/login';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MessageIcon from '@mui/icons-material/Message';
import NotificationsIcon from '@mui/icons-material/Notifications';
///bottom nav bar icons 
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined'
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HelpIcon from '@mui/icons-material/Help';
import PopupComponent from '../Popup/Popup';



function Navbar() {
  const navigate = useNavigate();
  const { currentUser } = useAuth()
  const username = currentUser ? currentUser.displayName : 'Guest'; // Provide a default name
  

  const [activeTab, setActiveTab] = useState('home');
  
  const handleClick = (tab) => {
    setActiveTab(tab)
  };
  const handleClickhome = () => {
   
    if (currentUser) {
     
      // If currentUser is not null, navigate to account page
        navigate('/home');
    } else {
        // If currentUser is null, call the popup
        navigate('/home');
    }
   };
  const handleClickacc = () => {
    setActiveTab('acc');
    if (currentUser) {
     
      // If currentUser is not null, navigate to account page
        navigate(`/account/${username}`);
    } else {
        // If currentUser is null, call the popup
        navigate('/login');
    }
};
const handleClicknotifications = () => {
 
  if (currentUser) {
      // If currentUser is not null, navigate to account page
      navigate(`/notifications/${username}`);
  } else {
      // If currentUser is null, call the popup
      navigate('/login');
  }
};

const handleClickpost = () => {
 
  if (currentUser) {
      // If currentUser is not null, navigate to account page
      
      navigate(`/post/${username}`);
  } else {
      // If currentUser is null, navigate to login page
      navigate('/login');
  }
};

const handleClickchat = () => {
  
  if (currentUser) {
  
    // If currentUser is not null, navigate to account page
      navigate(`/messages/${username}`);
  } else {
      // If currentUser is null, navigate to login page
      navigate('/login');
  }
};

const handleClicksaved = () => {
 
  if (currentUser) {
      // If currentUser is not null, navigate to account page
      navigate(`/saved/${username}`);
  } else {
      // If currentUser is null, navigate to login page
      navigate('/login');
  }
};

const handleClickhelp = () => {
 
  if (currentUser) {
      // If currentUser is not null, navigate to account page
      navigate('/help');
  } else {
      // If currentUser is null, navigate to login page
      navigate('/help');
  }
};
  return (
    <div className='Navbar'>
       
      <div className='Logo' onClick={() => navigate("/home")}>chipper lands</div>
      <div className='rightnav'>
       <div className='nav'>
  {/* Message Icon */}
  <span className='icon-container' id='offnigga'>
    <MessageIcon className='icon' onClick={handleClickchat} style={{ fontSize: '23px', color: 'gray' }} />
    <h1 className='text'>Messages</h1>
  </span>
  
  {/* Notifications Icon */}
  <span className='icon-container' id='offnigga' onClick={handleClicknotifications}>
    <NotificationsIcon className='icon' style={{ fontSize: '23px', color: 'gray' }} />
    <h1 className='text'>Notifications</h1>
  </span>
  
  {/* Special Folder Icon */}
  <span className='icon-container' id='offnigga'>
    <BookmarkAddIcon className='icon' onClick={handleClicksaved} style={{ fontSize: '23px', color: 'gray' }} />
    <h1 className='text'>Wishlist</h1>
  </span>
  
  {/* Account Icon */}
  <span className='icon-container' id='Account'>
    <AccountCircleIcon className='icon' onClick={handleClickacc} style={{ fontSize: '23px', color: 'gray' }} />
    <h1 className='text'>Account</h1>
  </span>
        </div>
       
        <div className='Bottomtabnav'>
        <div className='tabitem'>
            <span className='tabicon' onClick={handleClickhome}>
              <HomeOutlinedIcon></HomeOutlinedIcon>
              </span>
                <h1>Home</h1>
        </div>
        <div className='tabitem'>
        <span className='tabicon' onClick={handleClicksaved}>
              <BookmarkBorderOutlinedIcon></BookmarkBorderOutlinedIcon>
              </span> 
                <h1>Saved</h1>
        </div>
        <div className='tabitem'>
        <span className='tabicon'onClick={handleClickpost}>
        <AddBoxOutlinedIcon id="sellicon"></AddBoxOutlinedIcon>
        </span> 
                <h1>Sell</h1>
        </div>
        <div className='tabitem'>
        <span className='tabicon' onClick={handleClickchat}>
            <MessageOutlinedIcon></MessageOutlinedIcon>
            </span> 
                <h1>Messages</h1>
        </div>

        <div className="tabitem">
          <span className="tabicon" onClick={handleClickhelp}>
            <HelpOutlineIcon></HelpOutlineIcon>
          </span>
          <h1>help</h1>
        </div>
       
         </div>
        
            {/* <div className='Bottomtabnav'>
      <Link to="/home" className='tabitem' onClick={() => handleClick('home')}>
        <span className='tabicon'>
          <HomeOutlinedIcon style={{ fill: activeTab === 'home' ? 'maroon' : 'black' }} />
        </span>
        <h1>Home</h1>
      </Link>
      <Link to="/saved" className={`tabitem ${activeTab === 'saved' ? 'active' : ''}`} onClick={() => handleClick('saved')}>
        <span className='tabicon'>
          <BookmarkBorderOutlinedIcon style={{ fill: activeTab === 'saved' ? 'maroon' : 'black' }} />
        </span>
        <h1>Saved</h1>
      </Link>
      <Link to="/post" className={`tabitem ${activeTab === 'post' ? 'active' : ''}`} onClick={() => handleClick('post')}>
        <span className='tabicon'>
          <AddBoxOutlinedIcon id="sellicon" style={{ fill: activeTab === 'post' ? 'orange' : 'black' }} />
        </span>
        <h1>{activeTab === 'post' ? 'Sell' : 'Post'}</h1>
      </Link>
      <Link to="/messages" className={`tabitem ${activeTab === 'messages' ? 'active' : ''}`} onClick={() => handleClick('messages')}>
        <span className='tabicon'>
          <MessageOutlinedIcon style={{ fill: activeTab === 'messages' ? 'maroon' : 'black' }} />
        </span>
        <h1>Messages</h1>
      </Link>
      <Link to="/faqs" className={`tabitem ${activeTab === 'faqs' ? 'active' : ''}`} onClick={() => handleClick('faqs')}>
        <span className='tabicon'>
          {activeTab === 'faqs'?<HelpIcon style={{ fill: activeTab === 'faqs' ? 'maroon' : 'black' }} />:<HelpOutlineIcon/>}
        </span>
        <h1>Help</h1>
      </Link>
            </div> */}


        <div className='thebtn'>
          <button onClick={handleClickpost} className='sellbtn'>Sell</button>
        </div>
      </div>
      
    </div>
  );
}

export default Navbar;

