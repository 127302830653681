import React from 'react';
import './FAQs.css'; // Import CSS file for styling
import Navbar from "../Nav/Navbar";
const FAQs = () => {
  return (
    <>
    <Navbar/>
    <div className="faqs-container">
      <h1 className="title">Frequently Asked Questions</h1>

      <div className="faq">
        <h2 className="question">What types of properties can I post on Chipper Lands?</h2>
        <p className="answer">
          You can post various types of real estate properties including residential houses, apartments, commercial buildings, land, and more.
        </p>
      </div>

      <div className="faq">
        <h2 className="question">How do I create an account?</h2>
        <p className="answer">
          To create an account, click on the "Sign Up" button on the top right corner of the homepage. Fill out the required information and follow the instructions to complete the registration process.
        </p>
      </div>

      <div className="faq">
        <h2 className="question">Can I edit or delete my property listings?</h2>
        <p className="answer">
          Yes, you can edit or delete your property listings at any time by accessing your account dashboard and selecting the appropriate options for the listing you wish to modify.
        </p>
      </div>

      <div className="faq">
        <h2 className="question">How can I contact Chipper Lands for support?</h2>
        <p className="answer">
          You can contact us by filling out the contact form on the Contact Us page, or by sending an email to support@chipperlands.com. We strive to respond to all inquiries within 24 hours.
        </p>
      </div>
    </div>
    </>
    
  );
}

export default FAQs;
