import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/authContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import './Myads.css'
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"; 
import DeleteProduct from "../Sub_functions/DeleteDoc";

const Myads = () => {
  const [products, setProducts] = useState([]);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchProducts = async () => {
      if (!currentUser) return; // Ensure currentUser is available

      const q = query(
        collection(db, "products"),
        where("useremail", "==", currentUser.email)
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const fetchedProducts = [];
        snapshot.forEach((doc) => {
          fetchedProducts.push({ id: doc.id, ...doc.data() });
        });
        setProducts(fetchedProducts);
      });

      return () => unsubscribe();
    };

    fetchProducts();
  }, [currentUser]); // Ensure useEffect runs when currentUser changes

  return (
    <div className="myads">
      {products.length === 0 ? (
        <div className="no-ads">
        <p className="no-ads-message">Sorry, you haven't added any ads yet.</p>
        </div>
      ) : (
        <div className="productcard-container">
          {products.map((product, index) => (
            <div key={product.id} className="productcard" >
              {product.imgs.length > 0 && <img className="product_image" src={product.imgs[0]} alt="Product" onClick={() => navigate("/product", { state: product })}/>}
              <div className="product_details">
                <h1 className="product_price">UGX {product.price}</h1>
                <h1 className="product_title">{product.propertytitle}</h1>
                <DeleteForeverIcon className="Deleteicon" onClick={() => DeleteProduct(product.id,'products',product.propertytitle)}></DeleteForeverIcon>
                <h1 className="property_location">{product.selectedDistrict}, {product.selectedVillage}</h1>
                
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Myads;
