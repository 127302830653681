import React from 'react'
import "./menu.css"
import { useAuth } from '../../contexts/authContext'
import { Link,useNavigate } from 'react-router-dom'
import house from "../../assets/housesale.jpg"
import houserent from "../../assets/houserent.jpg"
import newbuild from "../../assets/newbuild.jpg"
import landsale from "../../assets/landsale.jpg"
import landrent from "../../assets/landrent.jpg"
import commercialrent from "../../assets/commercialproperty.jpg"
import shortlet from "../../assets/shortlet.jpg"
import event from "../../assets/event.jpg"
import AddBoxIcon from '@mui/icons-material/AddBox';


function Menu() {
  
   const navigate = useNavigate();
   const { currentUser } = useAuth()
   const username = currentUser ? currentUser.displayName : 'Guest'; // Provide a default name
   const housesforsale="Houses & Apartments for Sale";
   const housesforrent="Houses & Apartments for Rent";
   const LandPlotsforSale='Land & Plots for Sale';
   const LandPlotsforrent='Land & Plots for Rent';
   const Commercialpropertyforsale= 'Commercial Property for Sale';
   const Commercialpropertyforrent= 'Commercial Property for Rent';
   const ShortLetProperty='Short Let Property';
   const newbuilds='New Builds';
   const EventCentresVenuesWorkstations='Event Centres, Venues  Workstations';

  return (
    <div className='menu'>
   
    <div className="menuitem " id='postad' onClick={() => navigate(`/post/${username}`, { state: housesforsale })}> 
      <AddBoxIcon className='posticon' style={{ fontSize: '90px', color: 'orange' }}></AddBoxIcon>   <h1 > Post New Ad</h1>
    </div>    
    <div className="menuitem " onClick={() => navigate(`/products/${housesforsale}`, { state: housesforsale })}> 
      <img src={house}id='houserent'/>   <h1 > Houses & Apartments for Sale</h1>
    </div>
    <div className="menuitem " onClick={() => navigate(`/products/${housesforrent}`, { state: housesforrent })}> 
    <img src={houserent} />    <h1 > Houses & Apartments for Rent</h1>
           </div>
           <div className="menuitem "onClick={() => navigate(`/products/${LandPlotsforSale}`, { state: LandPlotsforSale })}> 
   <img src={landsale}/>      <h1 > Land & Plots for Sale</h1>
       </div>   
       <div className="menuitem " onClick={() => navigate(`/products/${Commercialpropertyforsale}`, { state: Commercialpropertyforsale })}> 
   <img src={commercialrent}/>      <h1> Commercial Property for Sale</h1>
          
           </div>   
           <div className="menuitem "onClick={() => navigate(`/products/${Commercialpropertyforrent}`, { state: Commercialpropertyforrent })}> 
   <img src={commercialrent}/>       <h1 > Commercial Property for Rent</h1>
           </div>   
           <div className="menuitem "  onClick={() => navigate(`/products/${ShortLetProperty}`, { state: ShortLetProperty })}> 
          
   <img src={shortlet}/>      <h1> Short Let Property</h1>
           </div>   
           <div className="menuitem "  onClick={() => navigate(`/products/${LandPlotsforrent}`, { state: LandPlotsforrent })}> 
          
   <img src={landrent}/>       <h1> Land & Plots for Rent</h1>
           </div>   
           <div className="menuitem " onClick={() => navigate(`/products/${newbuilds}`, { state: newbuilds })}> 
          
   <img src={newbuild}/>       <h1 >New Builds</h1>
          
           </div>   
           <div className="menuitem "  onClick={() => navigate(`/products/${EventCentresVenuesWorkstations}`, { state: EventCentresVenuesWorkstations })}> 
   <img src={event}/>       <h1>Event Centres, Venues & Workstations</h1>
          </div>
    </div>
  )
}

export default Menu