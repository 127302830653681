import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import SearchIcon from '@material-ui/icons/Search';

const SearchBartest = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (searchTerm.trim() !== '') {
        const q = query(
          collection(db, "products"),
          orderBy("propertytitle"),
          where("propertytitle", ">=", searchTerm),
          where("propertytitle", "<=", searchTerm + '\uf8ff')
        );
        const querySnapshot = await getDocs(q);
        const results = [];
        querySnapshot.forEach((doc) => {
          // Assuming doc.data() contains all the fields you mentioned
          results.push(doc.data());
        });
        setSearchResults(results);
      } else {
        setSearchResults([]);
      }
    };

    fetchData();
  }, [searchTerm]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
   
    <div class="search-container">
    <div class="search-bar">
        <input
            class="search-input"
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleInputChange}
        />
        {/* <button class="search-button" onClick={() => setSearchTerm(searchTerm)}>Search</button> */}
        <SearchIcon/>
    </div>
    <div id="search-result">
        {searchResults.map((product, index) => (
            <div class="product" key={index}>
                {product.imgs.length > 0 && <img class="product-image" src={product.imgs[0]} alt="Product" />}
                <h2 class="product-title">{product.propertytitle}</h2>
                <p class="product-info"> {product.price}</p>
                
                {/* Add other fields as needed */}
            </div>
        ))}
    </div>
</div>

  );
};

export default SearchBartest;
