// import React, { useState, useEffect } from 'react';
// import Navbar from '../Nav/Navbar';
// import { Link } from 'react-router-dom';
// import { collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
// import './chat.css'
// import { useAuth } from '../../contexts/authContext';
// import {  useLocation } from 'react-router-dom';
// import { doc, setDoc, getDoc } from "firebase/firestore";
// import { db} from "../../firebase/firebase";
// import { createOrderedChatroom } from '../Sub_functions/chatroom';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


// function Chat() {
//     const { currentUser } = useAuth();
//     const userpic =currentUser.photoURL;
//     const currentusername = currentUser.displayName;
//     const currentuseremail = currentUser.email;
//     const [messages, setMessages] = useState([]);
   
//    const senderemail=currentuseremail;
//    const sendername=currentusername;
    
//   const location = useLocation();
//   const data = location.state;
//   const receivername=data.sendername;
//   const msgdata=data.msgsent;
//   const msgdate=data.dateofmessage;
//   const msgsender=data.senderemail;
//   const msgreceiver=msgsender;
//   const receiveremail=msgreceiver;
//   const chatroomID=data.chatroomID;
//   const receiverpic=data.userpic;
//   const [msgsent, setmsgsent] = useState('');
  
  
//   const handleChange = (e) => {
//     setmsgsent(e.target.value);
//   };

//   const makeid=(length)=> {
//     let result = '';
//     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//     const charactersLength = characters.length;
//     let counter = 0;
//     while (counter < length) {
//       result += characters.charAt(Math.floor(Math.random() * charactersLength));
//       counter += 1;
//     }
//     return result;
//   }
  
//   const chatids = makeid(30);
 
//   const getCurrentDateTimeAsString = () => {
//     const currentDate = new Date();
//     const year = currentDate.getFullYear();
//     const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
//     const day = currentDate.getDate().toString().padStart(2, '0');
//     let hours = currentDate.getHours();
//     const amOrPm = hours >= 12 ? 'PM' : 'AM';
//     hours = hours % 12 || 12;
//     const minutes = currentDate.getMinutes().toString().padStart(2, '0');
//     const seconds = currentDate.getSeconds().toString().padStart(2, '0');
//     const shortDateFormatString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${amOrPm}`;
//     return shortDateFormatString;
//   }

//   const dateofmessage = getCurrentDateTimeAsString();
//   const mychatroomID = createOrderedChatroom(receiveremail, currentuseremail);

//   const sendmsg = async (e) => {
//     e.preventDefault();
//     try {
      
//       await setDoc(doc(db, "messages", chatids), {
//         receiveremail,
//         senderemail,
//         sendername,
//         msgsent,
//         dateofmessage,
//         chatroomID,
//         userpic

//       });
    
//       //navigate(-1)
//     } catch (err) {
//       console.log(err);
//       alert(err)
//     }
//   };
//   useEffect(() => {
//     const fetchMessages = async () => {
//         try {
//             // Query for messages sent by current user
//             const q1 = query(
//                 collection(db, 'messages'),
//                 where('chatroomID', '==', mychatroomID),
//                 orderBy('dateofmessage', 'asc')
//             );

          
//             // Subscribe to real-time updates for sender's and receiver's messages
//             const unsubscribe1 = onSnapshot(q1, (querySnapshot) => {
//                 const messageData1 = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//                 setMessages(prevMessages => {
//                     const updatedMessages = [...prevMessages.filter(msg => msg.chatroomID !== mychatroomID), ...messageData1];
//                     return updatedMessages.sort((a, b) => (a.dateofmessage > b.dateofmessage ? 1 : -1));
//                 });
//             });

          

//             // Return a cleanup function to unsubscribe from listeners
//             return () => {
//                 unsubscribe1();
               
//             };
//         } catch (error) {
//             console.error('Error fetching messages: ', error);
//         }
//     };

//     fetchMessages();

//     // Clean up function to unsubscribe from Firestore listener (if any)
//     return () => {
//         // If you have a Firestore listener, you can unsubscribe here
//     };
// }, [mychatroomID]);

//     return (
//         <div>
            
            
//             <div className="parent-wrapper">
                     
//             <div class='chatprofile'>
//                 <Link to={`/messages/${currentusername}`} className='backtomsglink'>
//                     <ArrowBackIosIcon className='backtomsg' style={{fontSize:'16px'}} />
//                  </Link>
//              <img src={receiverpic} alt="Profile" className="chatprofilepic" />
//                 <h1>{receivername}</h1>
//              </div> 
           
//             <ul className="message-list">
//                 {messages.map(message => (
                  
//                     <li key={message.id} className="message-item">
//                      <div className={`message-container ${message.senderemail === currentUser.email ? 'right-align' : 'left-align'}`}>
//                      <span>
//                       <div> <p class='msg'>{message.msgsent}</p>
//                         {/* <h3>from: {message.senderemail}</h3>
//                         <h3>To: {message.receiveremail}</h3> */}
//                         <h6>{message.dateofmessage}</h6>
//                        </div>
//                        </span>
//                         </div>
                       
//                     </li>
//                 ))}
//             </ul>
//             <form className="message-form" onSubmit={sendmsg}>
//                 <label>
                  
//                     <input
//                         type="text"
//                         value={msgsent}
//                         onChange={handleChange}
//                     />
//                 </label>
//                 <button type="submit">Send</button>
//             </form>
//         </div>
//         </div>
//     );
// }

// export default Chat;
import React, { useState, useEffect } from 'react';
import Navbar from '../Nav/Navbar';
import { Link } from 'react-router-dom';
import { collection, query, where, onSnapshot, orderBy } from 'firebase/firestore';
import './chat.css';
import { useAuth } from '../../contexts/authContext';
import { useLocation } from 'react-router-dom';
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { createOrderedChatroom } from '../Sub_functions/chatroom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function Chat() {
    const { currentUser } = useAuth();
    const userpic = currentUser.photoURL;
    const currentusername = currentUser.displayName;
    const currentuseremail = currentUser.email;
    const [messages, setMessages] = useState([]);
    const senderemail = currentuseremail;
    const sendername = currentusername;

    const location = useLocation();
    const data = location.state;
    const receivername = data.sendername;
    const msgdata = data.msgsent;
    const msgdate = data.dateofmessage;
    const msgsender = data.senderemail;
    const msgreceiver = msgsender;
    const receiveremail = msgreceiver;
    const chatroomID = data.chatroomID;
    const receiverpic = data.userpic;
    const [msgsent, setmsgsent] = useState('');

    const handleChange = (e) => {
        setmsgsent(e.target.value);
    };

    const makeid = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    const chatids = makeid(30);

    const getCurrentDateTimeAsString = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        let hours = currentDate.getHours();
        const amOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        const minutes = currentDate.getMinutes().toString().padStart(2, '0');
        const seconds = currentDate.getSeconds().toString().padStart(2, '0');
        const shortDateFormatString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${amOrPm}`;
        return shortDateFormatString;
    }

    const dateofmessage = getCurrentDateTimeAsString();
    const mychatroomID = createOrderedChatroom(receiveremail, currentuseremail);

    const sendmsg = async (e) => {
        e.preventDefault();
        try {
            await setDoc(doc(db, "messages", chatids), {
                receiveremail,
                senderemail,
                sendername,
                msgsent,
                dateofmessage,
                chatroomID,
                userpic
            });

            setmsgsent(''); // Clear the input field after sending a message
        } catch (err) {
            console.log(err);
            alert(err);
        }
    };

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const q1 = query(
                    collection(db, 'messages'),
                    where('chatroomID', '==', mychatroomID),
                    orderBy('dateofmessage', 'asc')
                );

                const unsubscribe1 = onSnapshot(q1, (querySnapshot) => {
                    const messageData1 = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    setMessages(prevMessages => {
                        const updatedMessages = [...prevMessages.filter(msg => msg.chatroomID !== mychatroomID), ...messageData1];
                        return updatedMessages.sort((a, b) => (a.dateofmessage > b.dateofmessage ? 1 : -1));
                    });
                });

                return () => {
                    unsubscribe1();
                };
            } catch (error) {
                console.error('Error fetching messages: ', error);
            }
        };

        fetchMessages();
    }, [mychatroomID]);

    return (
        <div>
            <div className="parent-wrapper">
                <div className='chatprofile'>
                    <Link to={`/messages/${currentusername}`} className='backtomsglink'>
                        <ArrowBackIosIcon className='backtomsg' style={{ fontSize: '16px' }} />
                    </Link>
                    <img src={receiverpic} alt="Profile" className="chatprofilepic" />
                    <h1>{receivername}</h1>
                </div>

                <ul className="message-list">
                    {messages.map(message => (
                        <li key={message.id} className="message-item">
                            <div className={`message-container ${message.senderemail === currentUser.email ? 'right-align' : 'left-align'}`}>
                                <span>
                                    <div>
                                        <p className='msg'>{message.msgsent}</p>
                                        <h6>{message.dateofmessage}</h6>
                                    </div>
                                </span>
                            </div>
                        </li>
                    ))}
                </ul>
                <form className="message-form" onSubmit={sendmsg}>
                    <label>
                        <input
                            type="text"
                            value={msgsent}
                            onChange={handleChange}
                        />
                    </label>
                    <button type="submit">Send</button>
                </form>
            </div>
        </div>
    );
}

export default Chat;
