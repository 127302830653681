// import React from 'react';
// import './Contact.css'; // Import CSS file for styling
// import Navbar from '../Nav/Navbar';

// const Contact = () => {
//   return (
//     <>
//     <Navbar></Navbar>
//      <div className="contact-container">
//       <h1 className="title">Contact Us</h1>
//       <p className="description">
//         Have questions, suggestions, or feedback? We'd love to hear from you! Please fill out the form below and we'll get back to you as soon as possible.
//       </p>
//       <form className="contact-form">
//         <div className="form-group">
//           <label htmlFor="name">Name:</label>
//           <input type="text" id="name" name="name" />
//         </div>
//         <div className="form-group">
//           <label htmlFor="email">Email:</label>
//           <input type="email" id="email" name="email" />
//         </div>
//         <div className="form-group">
//           <label htmlFor="message">Message:</label>
//           <textarea id="message" name="message" rows="5"></textarea>
//         </div>
//         <button type="submit" className="submit-btn">Send Message</button>
//       </form>
//     </div>
//     </>
   
//   );
// }

// export default Contact;
import React, { useState } from 'react';
import './Contact.css'; // Import CSS file for styling
import emailjs from '@emailjs/browser';

const EmailForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID')
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        alert('Email sent successfully!');
        setName('');
        setEmail('');
        setMessage('');
      }, (error) => {
        console.error('Failed to send email:', error.text);
        alert('Failed to send email. Please try again later.');
      });
  };

  return (
    <div className="email-form-container">
      <h2>Contact Us</h2>
      <form className="email-form" onSubmit={sendEmail}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" rows="5" value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
        </div>
        <button type="submit">Send Message</button>
      </form>
    </div>
  );
};

export default EmailForm;
