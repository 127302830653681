import { useState, useEffect } from "react";
import "./products.css"
import { Link, useNavigate } from 'react-router-dom';
import { collection, onSnapshot,orderBy,query } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CircularProgress from '@material-ui/core/CircularProgress';

function Allproducts({productsheader}) {
    const [pdts, setPdts] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const handleImageLoad = () => {
        setLoading(false);
    };

    useEffect(() => {
        const unsubscribe = onSnapshot(
            query(collection(db, "products"), orderBy("dateposted", "desc")),
            (snapshot) => {
                const productsData = [];
                snapshot.forEach((doc) => {
                    const { useremail, 
                            dateposted, 
                            category,
                            price,
                            description, 
                            propertytitle, 
                            imgs,
                            selectedDistrict,
                            selectedVillage, 
                            bathrooms,
                            bedrooms,
                            toilets,
                            negotiation,
                            listingby,
                            paymentper,
                            propertytype,
                            propertyuse,
                            propertysize,
                            furnishing,
                            facilities,
                            condition,
                            userphoneNumber,
                            userFullName,
                        } = doc.data();
                    
                    const productImages = Array.isArray(imgs) && imgs.length > 0 ? imgs : [];
                                    
                    productsData.push({
                        id: doc.id,
                        price,
                        selectedDistrict,
                        selectedVillage,
                        description,
                        propertytitle,
                        category,
                        useremail,
                        dateposted,
                        imgs: productImages,
                        bathrooms,
                        bedrooms,
                        toilets,
                        negotiation,
                        listingby,
                        paymentper,
                        propertytype,
                        propertyuse,
                        propertysize,
                        furnishing,
                        facilities,
                        condition,
                        userphoneNumber,
                        userFullName,
                    });
                });
                setPdts(productsData);
            },
            (error) => {
                console.log(error);
            }
        );
    
        return () => unsubscribe();
    }, []);
    

    return (
        <div className='productscontainer'>
            <h1 className="productsheader">{productsheader}</h1>
            
            <div className="productcard-container">
            {pdts.map((item, index) => (
                <div key={item.id} className="productcard" onClick={() => navigate(`/product/${item.category}/${item.propertytitle}`, { state: item })}>
                    {loading && 
                     <div className="loader-container">
                     <CircularProgress style={{ color: 'orange' }} />
                     </div>
                     }
                    <img
                        className="product_image"
                        src={item.imgs[0]}
                        alt="Product"
                        onLoad={handleImageLoad}
                        style={{ display: loading ? 'none' : 'block' }}
                    />
                    <div className="product_details">
                        <h1 className="product_price">UGX {item.price}</h1>
                        <h1 className="product_title">{item.propertytitle}</h1>
                        <h1 className="property_location">
                            <LocationOnIcon style={{ fontSize: '15px', color: 'gray' }} />
                            {item.selectedDistrict},{item.selectedVillage}
                        </h1>
                    </div>
                </div>
            ))}
        </div>
        </div>
    );
}

export default Allproducts;
